import { forEach } from 'lodash'

import { getFakePropertyName, getFakeAgentName } from '_v2/demo/services'
import { client } from '_v4/services/api'

import { ANALYTICS_GRAPHQL_ENDPOINTS } from '_v4/services/api/endpoints'
import { MarketingSourcesService } from './Components/Filters/services'

class GlobalDataService {
  static userData = null
  static agents = null
  static agentsById = null
  static company = null
  static properties = null
  static propertiesById = null
  static applications = null
  static marketingSources = null
  static toursGatedPropertyIds = null
  static callIntelGatedPropertyIds = null
  static callTranscriptGatedPropertyIds = null
  static callIntelStandalonePropertyIds = null
  static aiGatedPropertyIds = null
  static aiEmailGatedPropertyIds = null

  static propertyAttributes = null
  static propertyAttributesById = null
  static propertyAttributesByType = null

  static standaloneOnlyPropertiesAvailable = false
  static shouldExcludeStandaloneProperties = false

  static async initialize(excludeStandaloneProperties) {
    try {
      GlobalDataService.shouldExcludeStandaloneProperties = !!excludeStandaloneProperties
      await Promise.all([
        GlobalDataService._getUser(),
        GlobalDataService._getAgents(),
        GlobalDataService._getCompany(),
        GlobalDataService._getProperties(),
      ])
      await Promise.all([
        // Request only when properties data is loaded
        GlobalDataService._getMarketingSources(),
        GlobalDataService._getGatedProperties(),
      ])
    } catch (err) {
      console.log('GlobalDataService.initialize err - ', err.message)
    }
    return
  }

  static getPropertyNameById(propertyId) {
    const property = GlobalDataService.propertiesById[propertyId]

    return property === undefined ? 'N/A' : property.name
  }

  static _getAgents() {
    const { AGENTS } = ANALYTICS_GRAPHQL_ENDPOINTS

    return client
      .post(AGENTS.url, {
        query: AGENTS.query,
      })
      .then((response) => {
        const isDemo = localStorage.getItem('demo')
        const agents = response.data.data.allAgents

        if (isDemo) {
          GlobalDataService.agents = this._setDemoAgents(agents)
        }

        GlobalDataService.agents = agents.sort((agentA, agentB) => {
          return agentA.name.toLowerCase() > agentB.name.toLowerCase() ? 1 : -1
        })

        GlobalDataService.agentsById = agents.reduce((agentsById, nextAgent) => {
          nextAgent.name = nextAgent.name.trim()
          agentsById[nextAgent.id] = nextAgent

          return agentsById
        }, {})

        return agents
      })
  }

  static _getCompany() {
    const { COMPANY } = ANALYTICS_GRAPHQL_ENDPOINTS
    const REPORT_FUNC = 'company'
    return client
      .post(COMPANY.url, {
        query: COMPANY.query,
      })
      .then((response) => {
        GlobalDataService.company = response.data.data[REPORT_FUNC]
      })
  }

  static _getProperties() {
    const { PROPERTIES } = ANALYTICS_GRAPHQL_ENDPOINTS

    return client
      .post(PROPERTIES.url, {
        query: PROPERTIES.query,
      })
      .then((response) => {
        const isDemo = localStorage.getItem('demo')
        const properties = response.data.data.allProperties

        if (isDemo) {
          GlobalDataService.properties = this._setDemoProperties(properties)
        }

        GlobalDataService.properties = properties.sort((propertyA, propertyB) => {
          return propertyA.name.toLowerCase() > propertyB.name.toLowerCase() ? 1 : -1
        })

        GlobalDataService.propertiesById = properties.reduce((propertiesById, nextProperty) => {
          nextProperty.name = nextProperty.name.trim()
          propertiesById[nextProperty.id] = nextProperty
          return propertiesById
        }, {})
      })
  }

  static excludeStandalonePropertyIds = (propertyId) =>
    !GlobalDataService.callIntelStandalonePropertyIds.includes(propertyId)

  static _getGatedProperties() {
    const { GATED_PROPERTIES } = ANALYTICS_GRAPHQL_ENDPOINTS
    const REPORT_FUNC = 'getGatedProperties'
    return client
      .post(GATED_PROPERTIES.url, {
        query: GATED_PROPERTIES.query,
      })
      .then((response) => {
        GlobalDataService.toursGatedPropertyIds =
          response.data.data[REPORT_FUNC].toursGatedProperties
        GlobalDataService.callIntelGatedPropertyIds =
          response.data.data[REPORT_FUNC].callIntelGatedProperties
        GlobalDataService.callTranscriptGatedPropertyIds =
          response.data.data[REPORT_FUNC].callTranscriptGatedProperties
        GlobalDataService.aiGatedPropertyIds = response.data.data[REPORT_FUNC].aiGatedProperties
        GlobalDataService.aiEmailGatedPropertyIds =
          response.data.data[REPORT_FUNC].aiEmailGatedProperties
        GlobalDataService.callIntelStandalonePropertyIds =
          response.data.data[REPORT_FUNC].callIntelStandaloneProperties

        if (GlobalDataService.shouldExcludeStandaloneProperties) {
          const excludeStandalonePropertyIds = GlobalDataService.excludeStandalonePropertyIds
          GlobalDataService.toursGatedPropertyIds = GlobalDataService.toursGatedPropertyIds.filter(
            excludeStandalonePropertyIds
          )
          GlobalDataService.aiGatedPropertyIds = GlobalDataService.aiGatedPropertyIds.filter(
            excludeStandalonePropertyIds
          )
          GlobalDataService.aiEmailGatedPropertyIds =
            GlobalDataService.aiEmailGatedPropertyIds.filter(excludeStandalonePropertyIds)

          const knockCrmProperties =
            GlobalDataService.properties?.filter(
              (property) => !GlobalDataService.callIntelStandalonePropertyIds.includes(property.id)
            ) || []

          GlobalDataService.standaloneOnlyPropertiesAvailable =
            knockCrmProperties.length === 0 &&
            GlobalDataService.callIntelStandalonePropertyIds.length > 0
        }
      })
  }

  static _getUser() {
    const { USER_DATA } = ANALYTICS_GRAPHQL_ENDPOINTS
    const REPORT_FUNC = 'userData'
    return client
      .post(USER_DATA.url, {
        query: USER_DATA.query,
      })
      .then((response) => {
        GlobalDataService.userData = response.data.data[REPORT_FUNC]
      })
  }

  static _getMarketingSources() {
    const propertyIds = GlobalDataService.properties?.map((property) => property.id) || []

    return MarketingSourcesService(propertyIds).then((sources) => {
      GlobalDataService.marketingSources = sources
    })
  }

  static _generateAttributesById(propertyAttributes) {
    const mappings = {}
    forEach(propertyAttributes, (attribute, i) => {
      mappings[attribute.id] = attribute
    })
    return mappings
  }

  static _generateAttributesByType(propertyAttributes) {
    const mappings = {}
    forEach(propertyAttributes, (attribute, i) => {
      const attributeValues = mappings[attribute.attribute_type] || {}
      const attributesList = attributeValues[attribute.attribute_type_value] || []

      attributesList.push(attribute)

      attributeValues[attribute.attribute_type_value] = attributesList
      mappings[attribute.attribute_type] = attributeValues
    })
    return mappings
  }

  static _setDemoProperties(properties) {
    let usedNames = []

    properties.forEach((p) => {
      const transform = getFakePropertyName(usedNames)

      // update usedName list to ensure uniqueness
      usedNames = [...transform.usedNames]

      // set propertyName to fake name
      p.name = transform.fakeName
    })
    return properties
  }

  static _setDemoAgents(agents) {
    let usedNames = []

    agents.forEach((p) => {
      const transform = getFakeAgentName(usedNames)

      // update usedName list to ensure uniqueness
      usedNames = [...transform.usedNames]

      // set propertyName to fake name
      p.name = transform.fakeName
    })
    return agents
  }
}

export default GlobalDataService
