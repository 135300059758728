export const ACTIVITY_REPORT_QUERY = `

query ActivityReport($input: GetActivityReportInput!) {
  activityReport(input: $input) {
    properties {
      leads
      prospects
      followups
      email
      bulkEmail
      call
      text
      facebook
      visits
      walkIn
      appointments
      shows
      leases
      referrals
      qualification
      followupsPerProspect
      emailPerProspect
      bulkEmailPerProspect
      callPerProspect
      textPerProspect
      facebookPerProspect
      showPerVisit
      referralsPerProspect
      property
      propertyId
      agentData {
        leads
        prospects
        followups
        email
        bulkEmail
        call
        text
        facebook
        visits
        walkIn
        appointments
        shows
        leases
        referrals
        qualification
        followupsPerProspect
        emailPerProspect
        bulkEmailPerProspect
        callPerProspect
        textPerProspect
        facebookPerProspect
        showPerVisit
        referralsPerProspect
        agent
        agentId
      }
    }
    total {
      leads
      prospects
      followups
      email
      bulkEmail
      call
      text
      facebook
      visits
      walkIn
      appointments
      shows
      leases
      referrals
      qualification
      followupsPerProspect
      emailPerProspect
      bulkEmailPerProspect
      callPerProspect
      textPerProspect
      facebookPerProspect
      showPerVisit
      referralsPerProspect
      property
    }
  }
}
`
export const EXPORT_PDF_ACTIVITY_REPORT_MUTATION = `
mutation ExportActivityReport($input: ExportActivityReportInput!) {
  exportActivityReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_ACTIVITY_REPORT_MUTATION = `
mutation ExportActivityReport($input: ExportActivityReportInput!) {
  exportActivityReport(input: $input) {
    csvBase64
  }
}
`

export const SAVE_ACTIVITY_REPORT_MUTATION = `
mutation SaveActivityReport($input: SaveActivityReportInput!) {
  saveActivityReport(input: $input) {
    savedReportId
  }
}
`

export const SHARE_ACTIVITY_REPORT_MUTATION = `
mutation ShareActivityReport($input: ShareActivityReportInput!) {
  ShareActivityReport(input: $input) {
    response
  }
}
`
