declare global {
  interface Window {
    heap: any
  }
}

interface IdentifyHeapOptions {
  userId: number
  username: string
  companyId: number
  companyName: string
  userType: string
}

export function identify(options: IdentifyHeapOptions) {
  if (window.heap && window.heap?.identify) {
    window.heap.identify(options.username)

    const userProeprties = {
      'User ID': options.userId,
      Company: options.companyName,
      'Company ID': options.companyId,
      'User Level': options.userType,
    }
    window.heap.addUserProperties(userProeprties)
  }
}

export const resetIdentity = () => {
  if (window.heap && window.heap?.resetIdentity) {
    window.heap.resetIdentity()
  }
}

export const track = (eventName: string, options: any) => {
  if (window.heap && window.heap?.track) {
    window.heap.track(eventName, options)
  }
}
