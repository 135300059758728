import { useEffect } from 'react'
import {
  ApolloClient,
  DocumentNode,
  TypedDocumentNode,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { logError, ERROR_MSG_HANDLED } from '_v2/services/error'
import { useAlerts, AlertActionTypes, AlertSeverity } from '_v2/contexts/alerts'
import useFetchGraphQL from './useFetchGraphQL'

interface Props<VariablesTypes> {
  graphQLQuery: TypedDocumentNode | DocumentNode
  reportTitle: string
  variables: VariablesTypes
  client?: ApolloClient<any>
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}

const useFetchQuery = <QueryResponse, VariablesTypes>({
  graphQLQuery,
  reportTitle,
  variables,
  client,
  skip = false,
  fetchPolicy,
}: Props<VariablesTypes>) => {
  const { alertDispatch } = useAlerts()

  const transformFetchData = (data?: QueryResponse | null): QueryResponse | null => {
    if (!data) {
      return null
    }
    return data
  }

  const { isLoading, isError, errorMessage, data } = useFetchGraphQL<
    QueryResponse,
    QueryResponse,
    VariablesTypes
  >({ graphQLQuery, transformFetchData, params: variables, client, skip, fetchPolicy })

  useEffect(() => {
    if (isError) {
      logError({
        data: new Error(errorMessage?.message || 'Unknown error'),
        message: `${ERROR_MSG_HANDLED} ${reportTitle} useFetchQuery`,
      })
      alertDispatch({
        type: AlertActionTypes.ADD_ALERT,
        alert: {
          message: errorMessage?.message || 'Unknown error',
          type: AlertSeverity.ERROR,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, errorMessage])

  return { isLoading, isError, errorMessage, data }
}

export default useFetchQuery
