export const ID_VERIFICATION_REPORT_QUERY = `
query IdVerificationReport($input: GetIdVerificationReportInput!) {
  idVerificationReport(input: $input) {
    total {
      property
      visits
      visitsWithScan
      validScans
      invalidScans
      visitsWithScanRatio
      validIDRatio
      fraudExposure
    }
    properties {
      visits
      visitsIds
      visitsWithScan
      visitsWithScanIds
      validScans
      validScansIds
      invalidScans
      invalidScansIds
      visitsWithScanRatio
      validIDRatio
      fraudExposure
      property
      agentData {
        visits
        visitsIds
        visitsWithScan
        visitsWithScanIds
        validScans
        validScansIds
        invalidScans
        invalidScansIds
        visitsWithScanRatio
        validIDRatio
        fraudExposure
        agent
      }
    }
  }
}
`
export const EXPORT_PDF_ID_VERIFICATION_REPORT_MUTATION = `
mutation ExportIdVerificationReport($input: ExportIdVerificationReportInput!) {
  exportIdVerificationReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_ID_VERIFICATION_REPORT_MUTATION = `
mutation ExportIdVerificationReport($input: ExportIdVerificationReportInput!) {
  exportIdVerificationReport(input: $input) {
    csvBase64
  }
}
`

export const SAVE_ID_VERIFICATION_REPORT_MUTATION = `
mutation SaveIdVerificationReport($input: SaveIdVerificationReportInput!) {
  saveIdVerificationReport(input: $input) {
    savedReportId
  }
}
`

export const SHARE_ID_VERIFICATION_REPORT_MUTATION = `
mutation ShareIdVerificationReport($input: ShareIdVerificationReportInput!) {
  ShareIdVerificationReport(input: $input) {
    response
  }
}
`
