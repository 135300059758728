import { datadogRum } from '@datadog/browser-rum';


export function dataDogInit() {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'analytics-ui',
        env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
        version: '1.0.0',
        sampleRate: 25, // deprecated in future version of @datadog/browser-rum
        replaySampleRate: 100, // deprecated in future version of @datadog/browser-rum
        sessionSampleRate: 25,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
    });

    datadogRum.startSessionReplayRecording();
}

export function setDataDogUser(companyName, companyId, userName, userId) {
    const userData = {
        companyName: companyName,
        companyId: companyId,
        userName: userName,
        userId: userId,
    };

    datadogRum.setUser(userData);
}