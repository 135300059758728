export const GET_CALL_LOG_QUERY = `
query CallTranscriptsReport($input: GetCallTranscriptsReportInput!) {
  callTranscriptsReport(input: $input) {
      properties {
        property
        callTranscripts {
          agent
          caller
          callerStatus
          duration
          date
          time
          threadId
          streamId
          agentId
          excerpt
          mentions
        }
      }
    }
  }
`
