import React, { createContext, FC, useContext, useEffect, useReducer, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  addDays,
  differenceInDays,
  endOfToday,
  endOfYesterday,
  startOfTomorrow,
  subDays,
} from 'date-fns'
import { flatMap } from 'lodash'
// services
import { fetchAllAgents, fetchAllSources } from '_v2/contexts/services'
import { hasRequiredFilter } from '_v2/containers/Filters/services_v1'
import { useUser } from '_v2/contexts/userContext'
import { getTimeRangeFromStartEnd } from '_v2/containers/Filters/DaysHoursPicker/services'
// models
import { REPORT_METADATA } from '_v2/containers/Reports/models'
import { CALLER_TYPES, CallerType } from '_v2/containers/Filters/CallerTypes/models'
import { DaysHours } from '_v2/containers/Filters/DaysHoursPicker/models'
import { Status } from '_v2/containers/Filters/Statuses/models'
import {
  Agent,
  AgentSelectItem,
  contacts,
  dripCampaignStatuses,
  FilterOption,
  mapRelativeDateFutureValues,
  mapRelativeDateValues,
  NotAProspectValues,
  ProspectsByValues,
  RelativeDateRangeMap,
  RelativeDateRangeOptionValues,
  ReportDates,
  ReportPeriods,
  triggerTypes,
} from './models'
import { CALLER_TYPE_OPTIONS } from '_v4/containers/Filters/CallerTypesSelect/model'
import { CallerType as CallerTypeSelect } from 'Pages/Calls/query/queryTypes'
import { useCustomAttributes } from './hooks/useCustomAttributes'
import { ReportNamesBackendSupported } from 'Components/Filters/components/filters/BaseReport/constants'
import { Scheduled } from 'Pages/MyReports/query/queryTypes'

export enum FilterActionTypes {
  SET_FILTERS = 'SET_FILTERS',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  SET_ERROR = 'SET_ERROR',
}

export type FilterAction =
  | { type: FilterActionTypes.SET_FILTERS }
  | { type: FilterActionTypes.UPDATE_FILTERS; values: Partial<FilterState> }
  | { type: FilterActionTypes.SET_ERROR }

export enum DateByOptions {
  CREATED = 'created',
  EVENT = 'event',
}

interface ICustomAttributes {
  value: string
  group: string
  index: number
}

export interface FilterState {
  // Calls
  callerTypes: CallerType[]
  daysHours: DaysHours
  statuses: Status[]
  // GroupBy
  allGroupByOptions: string[]
  selectedGroupByOption: string
  // ViewBy
  allViewByOptions: string[]
  selectedViewByOption: string
  // ViewAs
  allViewAsOptions: string[]
  selectedViewAsOption: string
  // Caller Types
  callerTypesOptions: CallerTypeSelect[]
  // Call IDs for Call Log Report
  callIds: number[]
  selectedCallerType: CallerTypeSelect
  // Include non-prospects
  includeNonProspects: boolean
  // Prospects by create date
  prospectsByCreateDate: boolean
  // Show applications
  showApplications: boolean
  // Scan source
  scanSource: string | null
  // Sources
  allSources: string[]
  sources: string[]
  sourceByView: { [key: string]: string[] }
  // Contacts
  contacts: FilterOption[]
  contactOptions: FilterOption[]
  //Campaign statuses
  dripCampaignStatuses: FilterOption[]
  dripCampaignStatusOptions: FilterOption[]
  //Trigger types
  triggerTypes: FilterOption[]
  triggerTypeOptions: FilterOption[]
  // Agents
  agents: Agent[]
  // propertiesAgents: Agent[]
  propertiesAgents: number[]
  selectedAgents: AgentSelectItem[]
  selectedAgentIds: number[]
  isFetchingFinishForAgents: boolean
  // Dates
  relativeDateRange: RelativeDateRangeOptionValues
  relativeDateRangeMap: RelativeDateRangeMap[]
  relativeFutureDateRange: RelativeDateRangeOptionValues
  relativeDateRangeMapFuture: RelativeDateRangeMap[]
  dates: ReportPeriods
  futureDates: ReportDates
  dateBy: DateByOptions
  //Fraud
  averageFraudRate: number
  averageFraudCost: number
  // New Filters
  dateRanges: {
    period1: ReportDates
    period2: ReportDates
    period3: ReportDates
    next7days: ReportDates
  }
  notAProspect: NotAProspectValues
  notAProspectOptions: NotAProspectValues[]
  prospectsByOptions: ProspectsByValues[]
  prospectsBy: ProspectsByValues
  customAttributesGroup: string
  customAttributes: ICustomAttributes[]
  isFetchingFinishForCustomAttributes: boolean
  selectedAttributes: string[]
  selectedScheduled: Scheduled
  selectedBaseReport: ReportNamesBackendSupported[]
}

export const initialState: FilterState = {
  // Calls - in initial value is 'first-call'
  callerTypes: [CALLER_TYPES[1]],
  daysHours: {
    days: [{ title: 'All', value: -1 }],
    hours: getTimeRangeFromStartEnd(0, 23),
  },
  statuses: [{ title: 'All', value: '' }],
  // GroupBy
  allGroupByOptions: [],
  selectedGroupByOption: 'property',
  // ViewBy
  allViewByOptions: [],
  selectedViewByOption: '',
  // ViewAs
  allViewAsOptions: ['Count', 'Percentage'],
  selectedViewAsOption: 'Count',
  // Include non-prospects
  includeNonProspects: false,
  // Prospects by create date
  prospectsByCreateDate: true,
  // Show applications
  showApplications: false,
  // Scan source
  scanSource: null,
  // Sources
  allSources: [],
  sources: [],
  sourceByView: {},
  // Contacts
  contacts: [],
  contactOptions: contacts,
  //Campaign Statuses
  dripCampaignStatuses: [],
  dripCampaignStatusOptions: dripCampaignStatuses,
  //Trigger Types
  triggerTypes: [],
  triggerTypeOptions: triggerTypes,
  // Agents
  agents: [],
  propertiesAgents: [],
  selectedAgents: [{ id: -1, name: 'All' }],
  selectedAgentIds: [-1],
  isFetchingFinishForAgents: false,
  // Dates
  relativeDateRange: RelativeDateRangeOptionValues.LAST_7_DAYS,
  relativeDateRangeMap: mapRelativeDateValues(),
  relativeFutureDateRange: RelativeDateRangeOptionValues.NEXT_3_DAYS,
  relativeDateRangeMapFuture: mapRelativeDateFutureValues(),
  dates: {
    currentPeriod: { startDate: subDays(endOfToday(), 7), endDate: endOfYesterday() },
    priorPeriod: {
      startDate: subDays(endOfToday(), 14),
      endDate: subDays(endOfYesterday(), 7),
    },
  },
  futureDates: {
    startDate: startOfTomorrow(),
    endDate: addDays(startOfTomorrow(), 2),
  },
  dateBy: DateByOptions.EVENT,
  averageFraudRate: 0,
  averageFraudCost: 0,
  // New Filters
  notAProspect: NotAProspectValues.NO,
  notAProspectOptions: [NotAProspectValues.YES, NotAProspectValues.NO],
  prospectsByOptions: [ProspectsByValues.CREATE, ProspectsByValues.EVENT],
  prospectsBy: ProspectsByValues.EVENT,
  callerTypesOptions: CALLER_TYPE_OPTIONS,
  callIds: [],
  selectedCallerType: CallerTypeSelect.FIRST_TIME,
  dateRanges: {
    period1: {
      startDate: subDays(endOfToday(), 7),
      endDate: endOfToday(),
    },
    period2: {
      startDate: subDays(endOfToday(), 14),
      endDate: subDays(endOfYesterday(), 7),
    },
    period3: {
      startDate: subDays(endOfToday(), 14),
      endDate: subDays(endOfYesterday(), 7),
    },
    next7days: {
      startDate: startOfTomorrow(),
      endDate: addDays(startOfTomorrow(), 6),
    },
  },
  customAttributesGroup: '',
  customAttributes: [],
  isFetchingFinishForCustomAttributes: false,
  selectedAttributes: [],
  selectedScheduled: Scheduled.ALL,
  selectedBaseReport: [],
}

const filterReducer = (
  filterState: FilterState = initialState,
  action: FilterAction
): FilterState => {
  const { SET_FILTERS, UPDATE_FILTERS, SET_ERROR } = FilterActionTypes

  switch (action.type) {
    case SET_FILTERS:
      return {
        ...filterState,
      }
    case UPDATE_FILTERS: {
      return {
        ...filterState,
        ...action.values,
      }
    }
    case SET_ERROR: {
      return { ...filterState }
    }
    default:
      throw new Error('unhandled report action type')
  }
}

const FilterContext = createContext<{
  filterState: FilterState
  filterDispatch: (filterAction: FilterAction) => void
}>({ filterState: initialState, filterDispatch: () => {} })

const FilterProvider: FC = ({ children }) => {
  const [filterState, filterDispatch] = useReducer(filterReducer, initialState)
  const value = { filterState, filterDispatch }
  const { pathname } = useLocation()
  const [daysPrior] = useState(90)
  const user = useUser()
  const { data: customAttributesDataObject, isLoading: isLoadingCustomAttributes } =
    useCustomAttributes(user)
  const customAttributesData = customAttributesDataObject?.getCustomAttributes

  useEffect(() => {
    if (!customAttributesData) {
      return
    }
    let accIndex = 0
    const customAttributes = customAttributesData.map((attribute, index) => {
      if (index > 0 && customAttributesData) {
        accIndex += customAttributesData[index - 1].attributeValueList.length
      }
      return attribute.attributeValueList.map((value, innerIndex) => ({
        index: accIndex + innerIndex,
        value,
        group: attribute.attributeKey,
      }))
    })
    const newCustomAttributes = flatMap(customAttributes)
    filterDispatch({
      type: FilterActionTypes.UPDATE_FILTERS,
      values: {
        customAttributes: newCustomAttributes,
      },
    })
  }, [customAttributesData])

  useEffect(() => {
    if (!isLoadingCustomAttributes && !filterState.isFetchingFinishForCustomAttributes) {
      filterDispatch({
        type: FilterActionTypes.UPDATE_FILTERS,
        values: {
          isFetchingFinishForCustomAttributes: true,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCustomAttributes])

  const {
    dates: {
      currentPeriod: { startDate },
    },
    selectedViewByOption,
    selectedViewAsOption,
    allGroupByOptions,
    allViewByOptions,
    allViewAsOptions,
  } = filterState

  // Setting GroupBy / ViewBy default values for report filters based on pathname
  useEffect(() => {
    const selectedReport = REPORT_METADATA.find((report) => report.path === pathname)
    // GroupBy Setter
    if (selectedReport && selectedReport.groupByOptions && selectedReport.groupByOptions.length) {
      // Only update if different options
      if (!allGroupByOptions.length || selectedReport.groupByOptions !== allGroupByOptions) {
        filterDispatch({
          type: FilterActionTypes.UPDATE_FILTERS,
          values: {
            allGroupByOptions: selectedReport.groupByOptions,
            selectedGroupByOption: selectedReport.groupByOptions[0],
          },
        })
      }
    }
    // ViewBy Setter
    if (selectedReport && selectedReport.viewByOptions && selectedReport.viewByOptions.length) {
      // Only update if different options are available
      if (!allViewByOptions.length && selectedReport.viewByOptions !== allViewByOptions) {
        filterDispatch({
          type: FilterActionTypes.UPDATE_FILTERS,
          values: {
            allViewByOptions: selectedReport.viewByOptions,
            selectedViewByOption: selectedViewByOption || selectedReport.viewByOptions[0],
          },
        })
      }
    }

    // ViewAs Setter
    if (selectedReport && selectedReport.viewAsOptions && selectedReport.viewAsOptions.length) {
      if (!allViewAsOptions.length && selectedReport.viewAsOptions !== allViewAsOptions) {
        filterDispatch({
          type: FilterActionTypes.UPDATE_FILTERS,
          values: {
            allViewAsOptions: selectedReport.viewAsOptions,
            selectedViewAsOption: selectedViewAsOption || selectedReport.viewAsOptions[0],
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // Source useEffect
  useEffect(() => {
    // No need to fetch for reports that don't need it
    if (!hasRequiredFilter(pathname, 'sources')) return
    // calculate when source data needs to be fetched from.
    const today = new Date()
    const daysFromToday = differenceInDays(today, startDate)
    if (daysFromToday > daysPrior || !filterState.sources.length) {
      fetchAllSources().then((newSources) => {
        filterDispatch({
          type: FilterActionTypes.UPDATE_FILTERS,
          values: {
            sources: newSources,
          },
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pathname, startDate])

  // Agents
  useEffect(() => {
    let mounted = true
    if (user) {
      fetchAllAgents()
        .then((agents: Agent[]) => {
          if (mounted) {
            filterDispatch({
              type: FilterActionTypes.UPDATE_FILTERS,
              values: {
                agents,
                isFetchingFinishForAgents: true,
              },
            })
          }
        })
        .catch(() => {
          filterDispatch({
            type: FilterActionTypes.UPDATE_FILTERS,
            values: {
              isFetchingFinishForAgents: true,
            },
          })
        })
    }

    return () => {
      mounted = false
    }
  }, [user])

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}

const useFilterContext = () => {
  const ctx = useContext(FilterContext)
  if (ctx === undefined) {
    throw new Error('useFilterContext must be used within a FilterProvider.')
  }
  return ctx
}

export { FilterProvider, useFilterContext }
