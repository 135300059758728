export const ADDRESS_BOOK_QUERY = `
query AddressBook($input: AddressBookInput!) {
  addressBook(input: $input) {
    email
    first_name
    last_name
    property_id
    user_role
  }
}
`
