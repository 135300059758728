import React from 'react'

// components
import { LoaderFallback } from '_v2/common/components/Loaders'

// services
import { useUser } from './_v2/contexts/userContext'
import { identify } from 'hooks/useHeapAnalytics'
import GlobalDataService from 'GlobalDataService'

import { dataDogInit, setDataDogUser } from './ExternalServices/DataDogService';

// React.lazy()
// The React.lazy function lets you render a dynamic import as a regular component.
// This will automatically load the bundle containing the AuthenticatedApp/UnauthenticatedApp
// when App gets rendered.

// React.lazy takes a function that must call a dynamic import().
const loadAuthenticatedApp = () => import('./_v2/containers/App/AppAuthenticated')
const loadUnauthenticatedApp = () => import('./_v2/containers/App/AppUnauthenticated')

const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(loadUnauthenticatedApp)

function App() {
  const user = useUser()

  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  // React.useEffect(() => {
  //     loadAuthenticatedApp()
  // }, [])

  // If the module containing the AppAuthenticated/AppUnauthenticated is not yet loaded by the time App renders,
  // we must show some fallback content while we’re waiting for it to load - such as a loading indicator.
  // This is done using the Suspense component.
  if (user) {
    identify({
      userId: user.user_id,
      username: user.username,
      companyId: user.company_id,
      companyName: GlobalDataService.company?.name,
      userType: user.role,
    })

    dataDogInit();
    setDataDogUser(GlobalDataService.company?.name, user.company_id, user.username, user.user_id);
  }

  return (
    <React.Suspense fallback={<LoaderFallback />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}

export default App
