// INTENT
export const CALL_INTELLIGENCE_INTENT_REPORT_QUERY = `
  `

export const EXPORT_PDF_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION = `
mutation ExportIntentsReport($input: ExportIntentsReportInput!) {
  exportIntentsReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION = `
mutation ExportIntentsReport($input: ExportIntentsReportInput!) {
  exportIntentsReport(input: $input) {
    csvBase64
  }
}
`

export const SHARE_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION = `
mutation ShareIntentsReport($input: ShareIntentsReportInput!) {
  ShareIntentsReport(input: $input) {
    response
  }
}
`

export const SAVE_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION = `
mutation SaveIntentsReport($input: SaveIntentsReportInput!) {
  saveIntentsReport(input: $input) {
    savedReportId
  }
}
`

export const CALL_INTELLIGENCE_AGENT_THREAD_IDS_QUERY = `
query Query($input: QualificationReportThreadIdsInput!) {
  qualificationThreadBatchData(input: $input)
}
`

export const CALL_INTELLIGENCE_INTENT_THREAD_IDS_QUERY = `
query Query($input: IntentsReportBatchDataInput!) {
  intentsThreadBatchData(input: $input)
}
`

// AGENT
export const CALL_INTELLIGENCE_AGENT_REPORT_QUERY = `
  `

export const EXPORT_PDF_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION = `
mutation ExportQualificationReport($input: ExportQualificationReportInput!) {
  exportQualificationReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION = `
mutation ExportQualificationReport($input: ExportQualificationReportInput!) {
  exportQualificationReport(input: $input) {
    csvBase64
  }
}
`

export const SHARE_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION = `
mutation ShareQualificationReport($input: ShareQualificationReportInput!) {
  ShareQualificationReport(input: $input) {
  response
  }
}
`

export const SAVE_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION = `
mutation SaveQualificationReport($input: SaveQualificationReportInput!) {
  saveQualificationReport(input: $input) {
    savedReportId
  }
}
`
