import { createTheme } from '@material-ui/core/styles'
import * as colors from './styles/brandColors'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colors.color__primary,
    },
    secondary: {
      main: colors.color__secondary,
    },
    blue_dark: {
      main: colors.blue_dark.brand,
    },
    error: {
      main: colors.status__danger_red,
    },
    background: {
      default: colors.white,
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
    fontSize: 12,
    button: {
      textTransform: 'none',
      fontWeight: 300,
    },
    caption: {
      fontSize: '.6rem',
    },
    subtitle1: {
      fontSize: 16,
    },
  },
})

export default theme
