import queryString from 'query-string'
import { format } from 'date-fns'
// models
import { FILTERS_MAP } from './models_v1'
import { FILTER_METADATA } from './models'
import { REPORT_METADATA } from '../Reports/models'

//
// NOTE: This can be eliminated once the final dependencies are migrated:
// FavoriteReportDialog - can be removed once all reports have the new ReportActionBar
// MyReports/SavedReports/services.ts - filterServiceFromApiToPrettyPrint()
// |_ this service relies on transforming the `start` and `end` dates from ` * ` to today()
// |_ to safely remove, consider having the backend return today's date when end = '*'

// maps the current query string's keys to pretty print names
export const filterServiceFormatFiltersFromQueryString = () => {
  const reportParams = queryString.parse(window.location.search)
  if (!reportParams) {
    // TODO: return default report params if query string is null
    return null
  }

  let currentFilters = {}
  for (const [key, value] of Object.entries(reportParams)) {
    let formattedKey = filterServiceFormatNameFromWebKey(key)
    formattedKey = formattedKey ? formattedKey : key
    currentFilters[formattedKey] = value
  }
  return currentFilters
}

// maps api keys to pretty print names
export const filterServiceFromApiToPrettyPrint = filters => {
  let formattedKeys = {}
  const possibleDateKeys = [
    'start',
    'end',
    'startDate',
    'endDate',
    'start_date',
    'end_date',
    'date_start',
    'date_end',
  ]

  for (const [key, value] of Object.entries(filters)) {
    if (value)
      FILTERS_MAP.forEach(filter => {
        if (filter.api === key) {
          let formattedValue = value
          if (possibleDateKeys.includes(key)) {
            formattedValue =
              value === '*'
                ? format(new Date(), 'yyyy-MM-dd')
                : format(new Date(value), 'yyyy-MM-dd')
          }
          formattedKeys[filter.formatted] = formattedValue
        }
      })
  }
  return formattedKeys
}

// maps pretty print names to api key for save report call
export const filterServiceFormatFiltersForApi = filters => {
  let filtersAsApiKeys = {}

  for (const [key, value] of Object.entries(filters)) {
    let apiKey = filterServiceFormatFromPrettyToApi(key)
    apiKey = apiKey ? apiKey : key

    filtersAsApiKeys[apiKey] = value
  }

  return filtersAsApiKeys
}

// maps a filter's web keys to pretty print names
const filterServiceFormatNameFromWebKey = webName => {
  let formattedName = null

  FILTER_METADATA.forEach(filter => {
    if (filter.key === webName) {
      formattedName = filter.title
    }
  })
  return formattedName
}

// maps a filter's pretty print names to api snake_case keys
// can ditch this method and include it in filterServiceFormatFiltersForApi
// in a future refactor
const filterServiceFormatFromPrettyToApi = formattedName => {
  let apiName = null
  FILTERS_MAP.forEach(filter => {
    if (filter.formatted === formattedName) {
      apiName = filter.api
    }
  })
  return apiName
}

export const hasRequiredFilter = (path, filter) => {
  const selectedReport = REPORT_METADATA.find(report => path === report.path)
  if (!selectedReport) return false
  return (
    selectedReport.defaultFilters.includes(filter) ||
    selectedReport.additionalFilters.includes(filter)
  )
}
