export const GET_CALL_TRANSCRIPT_QUERY = `
query CallTranscript($threadId: Int!) {
    callTranscript(threadId: $threadId) {
      recordingUrl
      annotations {
        greeting
        name
        preferences
        prospectInfo
        prospectInterest
        prospectSource
        followup
      }
      utterances {
        speaker
        time
        transcript
      }
    }
  }
`

export const EXPORT_PDF_CALL_TRANSCRIPT_MUTATION = `
mutation ExportCallTranscriptsReport($input: ExportCallTranscriptsReportInput!) {
  exportCallTranscriptsReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_CALL_TRANSCRIPT_MUTATION = `
mutation ExportCallTranscriptsReport($input: ExportCallTranscriptsReportInput!) {
  exportCallTranscriptsReport(input: $input) {
    csvBase64
  }
}
`

export const SHARE_CALL_TRANSCRIPT_MUTATION = `
mutation ShareCallTranscriptsReport($input: ShareCallTranscriptsReportInput!) {
  ShareCallTranscriptsReport(input: $input) {
    response
  }
}
`

export const SAVE_CALL_TRANSCRIPT_MUTATION = `
mutation SaveCallTranscriptsReport($input: SaveCallTranscriptsReportInput!) {
  saveCallTranscriptsReport(input: $input) {
    savedReportId
  }
}
`
