export const CALLER_TYPE_METADATA = {
  title: 'Caller Type',
  caption: '',
  helperText: '',
}

export const CALLER_TYPES: CallerType[] = [
  { title: 'All', value: 'all' },
  { title: 'First Call', value: 'prospect-first-call' },
  { title: 'Return Call', value: 'prospect-return-call' },
  { title: 'Resident', value: 'resident' },
]

export interface CallerType {
  title: string
  value: string
}
