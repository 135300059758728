import React, { FC, useEffect } from 'react'
import { FormControl, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
// services
import { usePropertyState, usePropertyDispatch } from './PropertiesProvider'
// types
import { IProperty, PropertyActions } from './types'
// styles
import useStyles from './styles'

const PropertySingleSelect: FC = () => {
  const classes = useStyles()
  const { SingleFilter } = PropertyActions
  // ctx
  const propertyState = usePropertyState()
  const { knockCrmProperties, singleSelectPropertyId } = propertyState
  const propertyDispatch = usePropertyDispatch()

  useEffect(() => {
    // On routing from RedirectView - need to set singleSelectPropertyId
    if (knockCrmProperties.length && !singleSelectPropertyId) {
      propertyDispatch({
        type: SingleFilter,
        singleSelectPropertyId: knockCrmProperties[0].id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knockCrmProperties, singleSelectPropertyId])

  const handleChange = (e: React.ChangeEvent<{}>, value: IProperty | null) => {
    if (value) {
      return propertyDispatch({
        type: SingleFilter,
        singleSelectPropertyId: value.id,
      })
    }
  }

  const getDisplayValue = () => {
    return knockCrmProperties.find((p) => p.id === singleSelectPropertyId)
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <Autocomplete
          autoComplete
          autoHighlight
          onChange={handleChange}
          options={knockCrmProperties}
          getOptionLabel={(p: IProperty) => p.propertyName}
          disableCloseOnSelect
          value={getDisplayValue()}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Property"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </FormControl>
    </>
  )
}

export default PropertySingleSelect
