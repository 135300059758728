import {
  addDays,
  subDays,
  endOfToday,
  startOfToday,
  startOfTomorrow,
  startOfYesterday,
  endOfYesterday,
  endOfTomorrow,
  startOfMonth,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  startOfYear,
  subYears,
  endOfYear,
  subWeeks,
  startOfWeek,
  endOfWeek,
  endOfMonth,
  toDate,
  differenceInDays,
} from 'date-fns'

import {
  Agent,
  RelativeDateRangeOptionPretty,
  RelativeDateRangeOptionValues,
  AgentGraphQLResponse,
} from '_v2/contexts/models'
import { setDemoAgents } from '_v2/demo/services'
import { ContactOption } from '_v2/contexts/models'
import GlobalDataService from 'GlobalDataService'

export const fetchAllSources = async (): Promise<string[]> => {
  return GlobalDataService.marketingSources ?? []
}

export const fetchAllAgents = async (): Promise<Agent[]> => {
  let allAgents: Agent[] = []

  try {
    // TODO: improve error handling of empty responses

    const agents = GlobalDataService.agents || []
    agents.forEach((agent: AgentGraphQLResponse) => {
      allAgents.push({
        agentId: agent.id,
        companyId: agent.companyId,
        propertyId: agent.propertyId,
        name: agent.name,
      })
    })
    // #demo
    const isDemo = localStorage.getItem('demo')
    if (isDemo) {
      allAgents = await setDemoAgents(allAgents)
    }

    allAgents.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })

    return allAgents
  } catch (error) {
    console.error('Error fetching agents', error)
    return []
  }
}

export const formatContacts = (options: ContactOption[], contacts: string[]): ContactOption[] => {
  const updatedContacts: ContactOption[] = []

  options.forEach((option: ContactOption) => {
    contacts.forEach((contact: string) => {
      if (contact === option.value) {
        updatedContacts.push({ ...option })
      }
    })
  })

  return updatedContacts
}

/**
 * Helper to populate dropdown values for relative date ranges.
 * Maps values from pretty print(UI) to api values and visa versa
 * based on the valueToPretty boolean passed.
 * @param valueToPretty {boolean} returns the values in a key value pair of:
 * @returns array of key value pairs
 *
 * @example
 * if valueToPretty = true
 *      [
 *         {option: pretty},
 *         {option: pretty},
 *         {option: pretty},
 *         {option: pretty},
 *      ]
 *
 * if valueToPretty = false (default)
 *      [
 *         {pretty: option},
 *         {pretty: option},
 *         {pretty: option},
 *         {pretty: option},
 *      ]
 */
export const mapRelativeDateRanges = (valueToPretty: boolean = false) => {
  const options = Object.keys(RelativeDateRangeOptionValues)
  const pretty = Object.keys(RelativeDateRangeOptionPretty)

  let relativeDateRanges: { [key: string]: any } = []

  options.forEach((optKey) => {
    pretty.forEach((pretKey) => {
      if (pretKey === optKey) {
        valueToPretty
          ? relativeDateRanges.push({ optKey: pretKey })
          : relativeDateRanges.push({ pretKey: optKey })
      }
    })
  })

  return relativeDateRanges
}

export const calculateReportPeriodDates = (
  relativeDateRange: RelativeDateRangeOptionValues,
  startDate?: string,
  endDate?: string,
  state?: any
) => {
  const {
    TODAY,
    YESTERDAY,
    MTD,
    QTD,
    YTD,
    LAST_WEEK_SUN_SAT,
    LAST_WEEK_MON_SUN,
    LAST_WEEK_MON_FRI,
    LAST_WEEK_FRI_THURS,
    LAST_7_DAYS,
    LAST_7_DAYS_TODAY,
    LAST_14_DAYS,
    LAST_30_DAYS,
    LAST_MONTH,
    LAST_QUARTER,
    CUSTOM,
    TOMORROW,
    NEXT_3_DAYS,
    NEXT_7_DAYS,
    NEXT_14_DAYS,
    NEXT_30_DAYS,
  } = RelativeDateRangeOptionValues
  // default to last 7 days
  let reportPeriodDates: any = {
    currentPeriod: { startDate: subDays(endOfToday(), 7), endDate: endOfYesterday() },
    priorPeriod: {
      startDate: subDays(endOfToday(), 14),
      endDate: subDays(endOfYesterday(), 7),
    },
  }

  switch (relativeDateRange) {
    case TODAY:
      reportPeriodDates.currentPeriod.startDate = startOfToday()
      reportPeriodDates.currentPeriod.endDate = endOfToday()
      reportPeriodDates.priorPeriod.startDate = startOfYesterday()
      reportPeriodDates.priorPeriod.endDate = endOfYesterday()
      return reportPeriodDates

    case YESTERDAY:
      reportPeriodDates.currentPeriod.startDate = startOfYesterday()
      reportPeriodDates.currentPeriod.endDate = endOfYesterday()
      reportPeriodDates.priorPeriod.startDate = subDays(startOfYesterday(), 1)
      reportPeriodDates.priorPeriod.endDate = subDays(endOfYesterday(), 1)
      return reportPeriodDates

    case MTD:
      reportPeriodDates.currentPeriod.startDate = startOfMonth(endOfToday())
      reportPeriodDates.currentPeriod.endDate = endOfToday()
      reportPeriodDates.priorPeriod.startDate = startOfMonth(subMonths(endOfToday(), 1))
      reportPeriodDates.priorPeriod.endDate = endOfMonth(subMonths(endOfToday(), 1))
      return reportPeriodDates

    case QTD:
      reportPeriodDates.currentPeriod.startDate = startOfQuarter(endOfToday())
      reportPeriodDates.currentPeriod.endDate = endOfToday()
      reportPeriodDates.priorPeriod.startDate = subQuarters(startOfQuarter(endOfToday()), 1)
      reportPeriodDates.priorPeriod.endDate = subQuarters(endOfQuarter(endOfToday()), 1)
      return reportPeriodDates

    case YTD:
      reportPeriodDates.currentPeriod.startDate = startOfYear(endOfToday())
      reportPeriodDates.currentPeriod.endDate = endOfToday()
      reportPeriodDates.priorPeriod.startDate = subYears(startOfYear(endOfToday()), 1)
      reportPeriodDates.priorPeriod.endDate = subYears(endOfYear(endOfToday()), 1)
      return reportPeriodDates

    case LAST_WEEK_SUN_SAT:
      reportPeriodDates.currentPeriod.startDate = startOfWeek(subWeeks(endOfToday(), 1), {
        weekStartsOn: 0,
      })
      reportPeriodDates.currentPeriod.endDate = endOfWeek(subWeeks(endOfToday(), 1), {
        weekStartsOn: 0,
      })
      reportPeriodDates.priorPeriod.startDate = startOfWeek(subWeeks(endOfToday(), 2), {
        weekStartsOn: 0,
      })
      reportPeriodDates.priorPeriod.endDate = endOfWeek(subWeeks(endOfToday(), 2), {
        weekStartsOn: 0,
      })
      return reportPeriodDates

    case LAST_WEEK_MON_SUN:
      reportPeriodDates.currentPeriod.startDate = startOfWeek(subWeeks(endOfToday(), 1), {
        weekStartsOn: 1,
      })
      reportPeriodDates.currentPeriod.endDate = endOfWeek(subWeeks(endOfToday(), 1), {
        weekStartsOn: 1,
      })
      reportPeriodDates.priorPeriod.startDate = startOfWeek(subWeeks(endOfToday(), 2), {
        weekStartsOn: 1,
      })
      reportPeriodDates.priorPeriod.endDate = endOfWeek(subWeeks(endOfToday(), 2), {
        weekStartsOn: 1,
      })
      return reportPeriodDates

    case LAST_WEEK_MON_FRI:
      reportPeriodDates.currentPeriod.startDate = subWeeks(
        startOfWeek(endOfToday(), {
          weekStartsOn: 1,
        }),
        1
      )

      reportPeriodDates.currentPeriod.endDate = subDays(
        subWeeks(
          endOfWeek(endOfToday(), {
            weekStartsOn: 1,
          }),
          1
        ),
        2
      )

      reportPeriodDates.priorPeriod.startDate = subWeeks(
        startOfWeek(endOfToday(), {
          weekStartsOn: 1,
        }),
        2
      )

      reportPeriodDates.priorPeriod.endDate = subDays(
        subWeeks(
          endOfWeek(endOfToday(), {
            weekStartsOn: 1,
          }),
          2
        ),
        2
      )
      return reportPeriodDates

    case LAST_WEEK_FRI_THURS:
      reportPeriodDates.currentPeriod.startDate = startOfWeek(subWeeks(endOfYesterday(), 1), {
        weekStartsOn: 5,
      })
      reportPeriodDates.currentPeriod.endDate = endOfWeek(subWeeks(endOfYesterday(), 1), {
        weekStartsOn: 5,
      })
      reportPeriodDates.priorPeriod.startDate = startOfWeek(subWeeks(endOfYesterday(), 2), {
        weekStartsOn: 5,
      })
      reportPeriodDates.priorPeriod.endDate = endOfWeek(subWeeks(endOfYesterday(), 2), {
        weekStartsOn: 5,
      })
      return reportPeriodDates

    case LAST_7_DAYS:
      return reportPeriodDates

    case LAST_7_DAYS_TODAY:
      reportPeriodDates.currentPeriod.startDate = subDays(endOfToday(), 6)
      reportPeriodDates.currentPeriod.endDate = endOfToday()
      reportPeriodDates.priorPeriod.startDate = subDays(endOfToday(), 13)
      reportPeriodDates.priorPeriod.endDate = subDays(endOfToday(), 7)
      return reportPeriodDates

    case LAST_14_DAYS:
      reportPeriodDates.currentPeriod.startDate = subDays(endOfYesterday(), 13)
      reportPeriodDates.currentPeriod.endDate = endOfYesterday()
      reportPeriodDates.priorPeriod.startDate = subDays(endOfYesterday(), 27)
      reportPeriodDates.priorPeriod.endDate = subDays(endOfYesterday(), 14)
      return reportPeriodDates

    case LAST_30_DAYS:
      reportPeriodDates.currentPeriod.startDate = subDays(endOfYesterday(), 29)
      reportPeriodDates.currentPeriod.endDate = endOfYesterday()
      reportPeriodDates.priorPeriod.startDate = subDays(endOfYesterday(), 59)
      reportPeriodDates.priorPeriod.endDate = subDays(endOfYesterday(), 30)
      return reportPeriodDates

    case LAST_MONTH:
      reportPeriodDates.currentPeriod.startDate = startOfMonth(subMonths(endOfToday(), 1))
      reportPeriodDates.currentPeriod.endDate = endOfMonth(subMonths(endOfToday(), 1))
      reportPeriodDates.priorPeriod.startDate = startOfMonth(subMonths(endOfToday(), 2))
      reportPeriodDates.priorPeriod.endDate = endOfMonth(subMonths(endOfToday(), 2))
      return reportPeriodDates

    case LAST_QUARTER:
      reportPeriodDates.currentPeriod.startDate = subQuarters(startOfQuarter(endOfToday()), 1)
      reportPeriodDates.currentPeriod.endDate = subQuarters(endOfQuarter(endOfToday()), 1)
      reportPeriodDates.priorPeriod.startDate = subQuarters(startOfQuarter(endOfToday()), 2)
      reportPeriodDates.priorPeriod.endDate = subQuarters(endOfQuarter(endOfToday()), 2)
      return reportPeriodDates

    case CUSTOM:
      const parsedStart = startDate
        ? toDate(Date.parse(startDate))
        : state.dates.currentPeriod.startDate
      const parsedEnd = endDate ? toDate(Date.parse(endDate)) : state.dates.currentPeriod.endDate
      const distance = differenceInDays(parsedStart, parsedEnd) * -1

      reportPeriodDates.currentPeriod.startDate = parsedStart
      reportPeriodDates.currentPeriod.endDate = parsedEnd

      // subtract the distance in days between start and end from parsedStart
      reportPeriodDates.priorPeriod.startDate = subDays(parsedStart, distance)

      // starting at parsedStart, add the distance to get the correct end date
      reportPeriodDates.priorPeriod.endDate = addDays(subDays(parsedStart, distance), distance)

      return reportPeriodDates

    // These are future periods and have no priorPeriod
    case TOMORROW:
      reportPeriodDates.currentPeriod.startDate = startOfTomorrow()
      reportPeriodDates.currentPeriod.endDate = endOfTomorrow()
      return reportPeriodDates

    case NEXT_3_DAYS:
      reportPeriodDates.currentPeriod.startDate = startOfTomorrow()
      reportPeriodDates.currentPeriod.endDate = addDays(endOfTomorrow(), 2)
      return reportPeriodDates

    case NEXT_7_DAYS:
      reportPeriodDates.currentPeriod.startDate = startOfTomorrow()
      reportPeriodDates.currentPeriod.endDate = addDays(endOfTomorrow(), 6)
      return reportPeriodDates

    case NEXT_14_DAYS:
      reportPeriodDates.currentPeriod.startDate = startOfTomorrow()
      reportPeriodDates.currentPeriod.endDate = addDays(endOfTomorrow(), 13)
      return reportPeriodDates

    case NEXT_30_DAYS:
      reportPeriodDates.currentPeriod.startDate = startOfTomorrow()
      reportPeriodDates.currentPeriod.endDate = addDays(endOfTomorrow(), 29)
      return reportPeriodDates

    default:
      return reportPeriodDates
  }
}
