import PropTypes from 'prop-types'
import config from '_v2/config'

const LOCAL_STORAGE_TOKEN_NAME = config.TOKEN_ACCESS

async function client({ endpoint, method, body, ...custom_config } = {}) {
  client.propTypes = {
    optional_post: PropTypes.shape({
      endpoint: PropTypes.string.isRequired,
      method: PropTypes.string.isRequired,
      body: PropTypes.object,
      optional_custom_config: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  }

  const token = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)

  const headers = { 'content-type': 'application/json' }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config = {
    method: method,
    ...custom_config,
    headers: {
      ...headers,
      ...custom_config.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }

  return await window.fetch(`${endpoint}`, config).then(r => {
    if (r.status < 400) {
      return r.json()
    }
    return Promise.resolve({ status_code: 'Bad Request' })
  })
}

export default client
