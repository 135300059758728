/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CallsReport
// ====================================================

export interface CallsReport_callsReport_total {
  totalCalls: number
  totalCallsNonBusHr: number
  totalCallsNonBusHrPercentage: number | null
  totalCallsDuringBusHr: number
  totalCallsDuringBusHrPercentage: number | null
  answered: number
  answeredPercentage: number | null
  missed: number
  missedPercentage: number | null
  voicemail: number
  voicemailPercentage: number | null
  requestedText: number
  requestedTextPercentage: number | null
}

export interface CallsReport_callsReport_properties_dataByDate {
  totalCalls: number
  totalCallsNonBusHr: number
  totalCallsNonBusHrPercentage: number | null
  totalCallsDuringBusHr: number
  totalCallsDuringBusHrPercentage: number | null
  answered: number
  answeredPercentage: number | null
  missed: number
  missedPercentage: number | null
  voicemail: number
  voicemailPercentage: number | null
  requestedText: number
  requestedTextPercentage: number | null
  date: string | null
}

export interface CallsReport_callsReport_properties {
  totalCalls: number
  totalCallsNonBusHr: number
  totalCallsNonBusHrPercentage: number | null
  totalCallsDuringBusHr: number
  totalCallsDuringBusHrPercentage: number | null
  answered: number
  answeredPercentage: number | null
  missed: number
  missedPercentage: number | null
  voicemail: number
  voicemailPercentage: number | null
  requestedText: number
  requestedTextPercentage: number | null
  property: string
  propertyId: number
  dataByDate: CallsReport_callsReport_properties_dataByDate[] // Data grouped by date
}

export interface CallsReport_callsReport_callsByDay {
  dayOfWeek: number // Integer value for day of week such that 0 is Monday and 6 is Sunday
  answered: number
  missed: number
}

export interface CallsReport_callsReport_callsByHour {
  hour: number // Integer value for hour of day from 0 to 23
  answered: number
  missed: number
}

export interface CallsReport_callsReport_answerRateByDate {
  date: string
  answeredPercentage: number
  missedPercentage: number
}

export interface CallsReport_callsReport {
  total: CallsReport_callsReport_total | null
  properties: CallsReport_callsReport_properties[]
  callsByDay: CallsReport_callsReport_callsByDay[]
  callsByHour: CallsReport_callsReport_callsByHour[]
  answerRateByDate: CallsReport_callsReport_answerRateByDate[]
}

export interface CallsReport {
  callsReport: CallsReport_callsReport | null // Get a Calls report
}

export interface CallsReportVariables {
  input: GetCallsReportInput
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CallsReportDrillDown
// ====================================================

export interface CallsReportDrillDown_callsReport_properties_dataByDate {
  totalCalls: number
  answered: number
  answeredPercentage: number | null
  missed: number
  missedPercentage: number | null
  voicemail: number
  voicemailPercentage: number | null
  requestedText: number
  requestedTextPercentage: number | null
  date: string | null
  totalCallsNonBusHr: number
  totalCallsNonBusHrPercentage: number | null
}

export interface CallsReportDrillDown_callsReport_properties {
  property: string
  dataByDate: CallsReportDrillDown_callsReport_properties_dataByDate[] // Data grouped by date
}

export interface CallsReportDrillDown_callsReport {
  properties: CallsReportDrillDown_callsReport_properties[]
}

export interface CallsReportDrillDown {
  callsReport: CallsReportDrillDown_callsReport | null // Get a Calls report
}

export interface CallsReportDrillDownVariables {
  input: GetCallsReportInput
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

// CallerType filter for Calls Report
export enum CallerType {
  ALL = 'ALL',
  FIRST_AND_RETURN_CALL = 'FIRST_AND_RETURN_CALL',
  FIRST_TIME = 'FIRST_TIME',
  RESIDENT = 'RESIDENT',
  RETURN_CALL = 'RETURN_CALL',
}

// null
export interface GetCallsReportInput {
  propertyIds?: number[]
  startDate: string
  endDate: string
  callerType?: CallerType | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================

// Custom
interface CallsMetric {
  [col: string]: string
}

export const callsMetric: CallsMetric = {
  totalCalls: 'TOTAL_CALLS',
  answered: 'ANSWERED',
  missed: 'MISSED',
  voicemail: 'VOICEMAIL',
  requestedText: 'REQUESTED_TEXT',
  offHoursCalls: 'OFF_HOURS',
}
