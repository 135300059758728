import React, { FC, createContext, useContext, useReducer } from 'react'
import { AlertState, AlertActionTypes, AlertAction } from './types'

const initialState: AlertState = {
  alerts: [],
}

function alertReducer(state: AlertState = initialState, action: AlertAction): AlertState {
  const { SET_ALERTS, ADD_ALERT, REMOVE_ALERT, CLEAR_ALERTS } = AlertActionTypes

  switch (action.type) {
    case SET_ALERTS:
      return {
        ...state,
        alerts: action.alerts,
      }

    case CLEAR_ALERTS:
      return {
        ...state,
        alerts: [],
      }

    case ADD_ALERT:
      const newAlerts = [...state.alerts]
      // Compare to prevent duplicate alerts. Ideally this should never be the case, but ¯\_(ツ)_/¯
      const isEqual =
        JSON.stringify(action.alert) === JSON.stringify(state.alerts[state.alerts.length - 1])

      if (!isEqual) newAlerts.push(action.alert)

      return {
        ...state,
        alerts: newAlerts,
      }

    case REMOVE_ALERT:
      const modifiedAlerts = [...state.alerts]
      modifiedAlerts.splice(action.index, 1)

      return {
        ...state,
        alerts: modifiedAlerts,
      }

    default:
      throw new Error('unhandled alert action type')
  }
}

const AlertContext = createContext<{
  alertState: AlertState
  alertDispatch: (action: AlertAction) => void
}>({ alertState: initialState, alertDispatch: () => {} })

const AlertProvider: FC = ({ children }) => {
  const [alertState, alertDispatch] = useReducer(alertReducer, initialState)
  const value = { alertState, alertDispatch }

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

const useAlerts = () => {
  const ctx = useContext(AlertContext)
  if (ctx === undefined) {
    throw new Error('useAlerts must be used within a AlertProvider.')
  }
  return ctx
}

export { AlertProvider, useAlerts }
