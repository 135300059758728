export const ALL_AGENTS_QUERY = `
query AllAgents {
  allAgents {
    id
    name
    companyId
    propertyId
  }
}
`
