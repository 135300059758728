import { decodeToken, Token, DecodedToken, getTokens } from '_v2/services/token'

export const ERROR_MSG_FATAL = 'error:fatal - CAUGHT in'
export const ERROR_MSG_HANDLED = 'error:handled - in'

interface LogErrorProps {
  data: Error
  message?: string
  additional?: any
}

export const logError = ({ data, message, additional }: LogErrorProps): void => {
  try {
    const tokens: Token | undefined = getTokens()

    let payload = {
      knock_message: message,
      knock_has_custom_attrs: true,
      ...additional,
    }

    // decode token, then send NR user profile data
    if (tokens) {
      const decodedToken: DecodedToken = decodeToken(tokens.accessToken)
      const { companyId, userId, role } = decodedToken
      payload.knock_company_id = companyId
      payload.knock_user_id = userId
      payload.knock_user_role = role
      payload.knock_user_access_token = tokens.accessToken
      payload.knock_user_refresh_token = tokens.refreshToken
    }
  } catch (err) {
    // log it out if NR fails
    console.error(err)
  }
}
