/**
 * Helpers to handle interactions with local storage.
 */
export interface ILocalStorage {
  key: string
  value?: any
}

/**
 * Attempts to retrieve the value associated with passed-in key.
 * @param item ILocalStorage: key/value
 * @returns local storage value or {}
 */
export const getItem = (item: ILocalStorage): string => {
  const { key } = item
  const returnItem = localStorage.getItem(key)
  if (typeof returnItem === 'string') {
    return returnItem
  } else {
    return returnItem !== null ? JSON.parse(returnItem) : {}
  }
}

/**
 * Sets/updates the provided ILocalStorage key/value paid.
 * @param item ILocalStorage key/value pair.
 */
export const setItem = (item: ILocalStorage): void => {
  const { key, value } = item
  localStorage.setItem(key, value)
}

/**
 * Attempts to remove the key/value pair from local storage.
 * @param item ILocalStorage: key/value
 */
export const removeItem = (item: ILocalStorage): void => {
  const { key } = item
  localStorage.removeItem(key)
}

/**
 * Clears local storage for the given host.
 */
export const clearAllItems = (): void => {
  localStorage.clear()
}
