import LaunchDarklyClientProvider from 'knock-utils/feature-flag/launch-darkly-client-provider'
import { ClientFeatureFlagService } from 'knock-utils/feature-flag/client-feature-flag-service'
import config from '_v4/config'

const IS_ANALYTICS_DRIP_CAMPAIGNS_ENABLED = 'isAnalyticsDripCampaignsEnabled'
const IS_CALL_INTEL_FILTER_ANA_ENABLED = 'isCallIntelAnaEnabled'
const IS_CALL_INTEL_STANDALONE_ANA_ENABLED = 'isCallIntelStandaloneAnaEnabled'

const featureFlags = {
  ANALYTICS_DRIP_CAMPAIGNS_ENABLED: 'analytics-drip-campaigns',
  CALL_INTEL_FILTER_ANA_ENABLED: 'call-intel-filter-ana',
  CALL_INTEL_STANDALONE_ANA_ENABLED: 'call-intel-standalone-ana',
}

const INITIAL_FEATURE_FLAGS_VALUE = {
  [IS_ANALYTICS_DRIP_CAMPAIGNS_ENABLED]: false,
  [IS_CALL_INTEL_FILTER_ANA_ENABLED]: false,
  [IS_CALL_INTEL_STANDALONE_ANA_ENABLED]: false,
}

const provider = new LaunchDarklyClientProvider(config.LAUNCH_DARKLY_CLIENTSIDE_ID)
const featureFlagService = new ClientFeatureFlagService(provider)

export {
  featureFlags,
  featureFlagService,
  INITIAL_FEATURE_FLAGS_VALUE,
  IS_ANALYTICS_DRIP_CAMPAIGNS_ENABLED,
  IS_CALL_INTEL_FILTER_ANA_ENABLED,
  IS_CALL_INTEL_STANDALONE_ANA_ENABLED,
}
