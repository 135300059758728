export interface AlertState {
  alerts: Alert[]
}

export interface Alert {
  type: AlertSeverity
  message: string | undefined
}

export enum AlertActionTypes {
  SET_ALERTS = 'SET_ALERTS',
  ADD_ALERT = 'ADD_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
  CLEAR_ALERTS = 'CLEAR_ALERTS',
}

export enum AlertSeverity {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export type AlertAction =
  | { type: AlertActionTypes.SET_ALERTS; alerts: Alert[] }
  | { type: AlertActionTypes.ADD_ALERT; alert: Alert }
  | { type: AlertActionTypes.REMOVE_ALERT; index: number }
  | { type: AlertActionTypes.CLEAR_ALERTS }
