import config from '_v2/config'
import { ViewByTypes } from '_v2/containers/Filters/models'
import { ReportKeys } from '_v4/containers/Reports/models'

// comments indicate the host url if different from Analytics API
const { CRM_API, CRM_WEB, ANALYTICS_API, ANALYTICS_WEB, ADMIN_WEB, SYNDICATION_AUTH_API } = config

// auth
export const AUTH_ENDPOINTS = {
  LOGIN: { host: CRM_API, url: `/auth/validate`, method: 'POST' },
  LOGOUT: {
    host: SYNDICATION_AUTH_API,
    url: `/logout?redirect_url=${ANALYTICS_WEB}`,
    method: 'REDIRECT',
  },
  RESET_PW: { host: CRM_API, url: `/auth/password/forgot`, method: 'POST' },
  REFRESH_TOKEN: { host: CRM_API, url: `/auth/refresh`, method: 'POST' },
  CRM_TRANSFER: {
    host: CRM_WEB,
    url: `/autologin?token={accessToken}`,
    method: 'REDIRECT',
  },
  ADMIN_TRANSFER: {
    host: ADMIN_WEB,
    url: `/login?accessToken={accessToken}`,
    method: 'REDIRECT',
  },
}

// insights
export const INSIGHTS_ENDPOINTS = {
  GET_REPORT_CARD: {
    host: ANALYTICS_API,
    url: `/insights/{report}/{graph}`,
    method: 'GET',
  },
  GET_USER_PREFERENCES: {
    host: ANALYTICS_API,
    url: `/insights/{report}/{graph}`,
    method: 'GET',
  },
  SHARE_INSIGHTS_DRILLDOWN: {
    host: ANALYTICS_API,
    url: `/insights/share`,
    method: 'POST',
  },
}

// my reports
export const MY_REPORTS_ENDPOINTS = {
  // saved reports
  LIST_SAVED_REPORTS: {
    host: ANALYTICS_API,
    url: `/saved-reports`,
    method: 'GET',
  },
  GET_SAVED_REPORT: {
    host: ANALYTICS_API,
    url: (savedReportId: number) => `/saved-reports/${savedReportId}`,
    method: 'GET',
  },
  CREATE_SAVED_REPORT: {
    host: ANALYTICS_API,
    url: `/saved-reports/create`,
    method: 'POST',
  },
  DELETE_SAVED_REPORT: {
    host: ANALYTICS_API,
    url: (savedReportId: number) => `/saved-reports/delete/${savedReportId}`,
    method: 'PUT',
  },

  // graphql save reports
  GRAPHQL_CREATE_SAVED_REPORT: {
    host: ANALYTICS_API,
    url: '',
    method: 'POST',
  },

  // scheduled reports
  LIST_SCHEDULED_REPORTS: {
    host: ANALYTICS_API,
    url: `/scheduled-reports`,
    method: 'GET',
  },
  CREATE_SCHEDULED_REPORT: {
    host: ANALYTICS_API,
    url: `/scheduled-reports/create`,
    method: 'POST',
  },
  DELETE_SCHEDULED_REPORT: {
    host: ANALYTICS_API,
    url: (scheduledReportId: number) => `/scheduled-reports/delete/${scheduledReportId}`,
    method: 'PUT',
  },

  // recipients of scheduled reports
  ADD_SUBSCRIBER: {
    host: ANALYTICS_API,
    url: `/my-reports/scheduled/{id}/subscribers/add`,
    method: 'POST',
  },
  DELETE_SUBSCRIBER: {
    host: ANALYTICS_API,
    url: `/my-reports/scheduled/{id}/subscribers/delete`,
    method: 'PUT',
  },
}

// standard reports
// because some of the api has been converted to using all GET methods,
// and other endpoints haven't, using this key to track which is which
export const STANDARD_REPORTS_ENDPOINTS = {
  ACTIVITY: {
    host: ANALYTICS_API,
    url: `/reports/activity`,
    method: 'GET',
    reportKey: ReportKeys.ACTIVITY,
  },
  ACTIVITY_BY_AGENT: {
    host: ANALYTICS_API,
    url: (propertyId: string) => `/reports/activity/property/${propertyId}/agents`,
    method: 'GET',
    reportKey: '',
  },
  AD_SPEND: {
    host: ANALYTICS_API,
    url: `/reports/ad-spend`,
    method: 'GET',
    reportKey: ReportKeys.AD_SPEND,
  },
  APPOINTMENTS: {
    host: ANALYTICS_API,
    url: (viewBy: string) => `/reports/appointments/${viewBy}`,
    method: 'GET',
    reportKey: ReportKeys.APPOINTMENTS,
  },
  APPOINTMENTS_BY_PROSPECT: {
    host: ANALYTICS_API,
    url: (viewBy: string, id: number) =>
      `/reports/appointments/${viewBy}/${encodeURIComponent(id)}/prospects`,
    method: 'GET',
    reportKey: ReportKeys.APPOINTMENTS_UPCOMING,
  },
  BOXSCORE: {
    host: ANALYTICS_API,
    url: `/boxscore-report`,
    method: 'GET',
    reportKey: ReportKeys.BOXSCORE,
  },
  BOXSCORE_RESIDENT: {
    host: ANALYTICS_API,
    url: `/reports/boxscore-resident`,
    method: 'GET',
    reportKey: '',
  },
  CALL_LOG: {
    host: ANALYTICS_API,
    // url: `/reports/call-log`,
    // method: 'GET',
    url: '/call-log',
    method: 'POST',
    reportKey: ReportKeys.CALL_LOG,
  },
  CALLS: {
    host: ANALYTICS_API,
    url: `/reports/calls`,
    method: 'GET',
    reportKey: ReportKeys.CALLS,
  },
  CONVERSIONS: {
    host: ANALYTICS_API,
    url: `/reports/conversions`,
    method: 'GET',
    reportKey: '',
  },
  CRAIGSLIST: {
    host: ANALYTICS_API,
    url: `/reports/craigslist`,
    method: 'GET',
  },
  LOSS_REASONS: {
    host: ANALYTICS_API,
    url: '/reports/loss-reasons',
    method: 'GET',
    reportKey: ReportKeys.LOSS_REASONS,
  },
  ENGAGEMENT: {
    host: ANALYTICS_API,
    url: `/reports/engagement`,
    method: 'GET',
    reportKey: ReportKeys.ENGAGEMENT,
  },
  ENGAGEMENT_DAILY: {
    host: ANALYTICS_API,
    url: `/reports/engagement/daily`,
    method: 'GET',
    reportKey: '',
  },
  ID_VERIFICATION: {
    host: ANALYTICS_API,
    url: `/reports/id-verification`,
    method: 'GET',
    reportKey: ReportKeys.ID_VERIFICATION,
  },
  OCCUPANCY: {
    host: ANALYTICS_API,
    url: `/reports/occupancy`,
    method: 'GET',
    reportKey: ReportKeys.OCCUPANCY,
  },
  MTA: {
    host: ANALYTICS_API,
    url: (viewBy: string) =>
      viewBy === ViewByTypes.ALL ? '/reports/mta' : `/reports/mta/${viewBy}`,
    method: 'GET',
    reportKey: ReportKeys.MTA,
  },
  PIPELINE: { host: ANALYTICS_API, url: `/reports/pipeline`, method: 'GET', reportKey: '' },
  RENEWALS: {
    host: ANALYTICS_API,
    url: `/reports/renewals`,
    method: 'GET',
    reportKey: ReportKeys.RENEWALS,
  },
  RESPONSE_TIME: {
    host: ANALYTICS_API,
    url: `/reports/response-time`,
    method: 'GET',
    reportKey: ReportKeys.RESPONSE_TIME,
  },
  RESPONSE_TIME_PROSPECT_BATCH_TOKENS: {
    host: ANALYTICS_API,
    url: `/reports/response-time/prospect-batch-tokens`,
    method: 'GET',
    reportKey: '',
  },
  SESSIONS: { host: ANALYTICS_API, url: `/reports/sessions`, method: 'GET', reportKey: '' },
  SNAPSHOT: { host: ANALYTICS_API, url: `/reports/snapshot`, method: 'GET', reportKey: '' },
  SUBSCRIBERS: {
    host: ANALYTICS_API,
    url: `/reports/subscribers`,
    method: 'GET',
    reportKey: '',
  },
  TOUR_TYPE: {
    host: ANALYTICS_API,
    url: `/reports/tour-type`,
    method: 'GET',
    reportKey: ReportKeys.TOUR_TYPE,
  },
  TOUR_ENGAGEMENT: {
    host: ANALYTICS_API,
    url: `/reports/tour-engagement`,
    method: 'GET',
    reportKey: ReportKeys.TOUR_ENGAGEMENT,
  },
  DEMOGRAPHICS: {
    host: ANALYTICS_API,
    url: `/reports/demographics`,
    method: 'GET',
    reportKey: ReportKeys.DEMOGRAPHICS,
  },
  TRAFFIC: {
    host: ANALYTICS_API,
    url: `/reports/traffic`,
    method: 'GET',
    reportKey: ReportKeys.TRAFFIC,
  },
}

// filters
export const FILTER_ENDPOINTS = {
  AGENTS: {
    host: ANALYTICS_API,
    url: '/agents',
    method: 'GET',
  },
  PROPERTIES: {
    host: ANALYTICS_API,
    url: '/properties',
    method: 'GET',
  },
  PROPERTY_ATTRIBUTES: {
    host: ANALYTICS_API,
    url: '/property-attributes',
    method: 'GET',
  },
}

// utils
export const UTILS_ENDPOINTS = {
  USER_PROFILE: { host: CRM_API, url: `/profile`, method: 'GET' },
  USER_ADDRESS_BOOK: { host: ANALYTICS_API, url: '/address-book', method: 'GET' },
  SEND_REPORT: { host: ANALYTICS_API, url: '/share-report', method: 'POST' },
  SEND_INSIGHTS: { host: ANALYTICS_API, url: '/share-insights', method: 'POST' },
  PROSPECT_BATCH_URLS: {
    host: ANALYTICS_API,
    url: '/get-prospect-batch-tokens-by-id',
    method: 'GET',
  },
  PROSPECT_BATCH_CRM_TRANSFER: {
    host: CRM_WEB,
    url: (loginToken: string, batchId: string) => {
      const path = encodeURIComponent(`/reports/prospects?batchId=${batchId}&status=all`)
      return `${config.CRM_WEB}/autologin?token=${loginToken}&to=${path}`
    },
  },
}
