import { MarketingSourcesDataResponse } from '_v4/shared/types'
import { ServerResponse, endpoints, client } from '_v4/services/api'
import { orderBy } from 'lodash'

export const MarketingSourcesService = async (propertyIds: number[]): Promise<Array<string>> => {
  const {
    MARKETING_SOURCES: { query, url },
  } = endpoints.ANALYTICS_GRAPHQL_ENDPOINTS

  try {
    const response: ServerResponse<MarketingSourcesDataResponse> = await client.post(url, {
      query,
      variables: { propertyIds },
    })

    const {
      data: {
        data: { marketingSources },
      },
    } = response
    const sourcesOrdered = orderBy(marketingSources || [], ['source'], 'asc')

    return sourcesOrdered.map((entry) => entry.source)
  } catch (error) {
    console.error(error)
    throw error
  }
}
