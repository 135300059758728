export const FILTERS_MAP = [
  { api: 'property_ids', web: 'propertyIds', formatted: 'Properties', defaultValue: [] },
  { api: 'agent_ids', web: 'agentIds', formatted: 'Agents', defaultValue: [] },
  {
    api: 'relative_date_range',
    web: 'relativeDateRange',
    formatted: 'Date Range',
    defaultValue: null,
  },
  { api: 'start', web: 'startDate', formatted: 'Start Date', defaultValue: null },
  { api: 'end', web: 'endDate', formatted: 'End Date', defaultValue: null },
  { api: 'startDate', web: 'startDate', formatted: 'Start Date', defaultValue: null },
  { api: 'endDate', web: 'endDate', formatted: 'End Date', defaultValue: null },
  { api: 'group_by', web: 'groupBy', formatted: 'View By', defaultValue: null },
  { api: 'sources', web: 'sources', formatted: 'Sources', defaultValue: [] },
  { api: 'contacts', web: 'contacts', formatted: 'Contacts', defaultValue: [] },
  {
    api: 'leasing_teams',
    web: 'leasingTeams',
    formatted: 'Leasing Teams',
    defaultValue: [],
  },
  { api: 'statuses', web: 'statuses', formatted: 'Statuses', defaultValue: [] },
  { api: 'days', web: 'days', formatted: 'Days', defaultValue: [] },
  { api: 'hours', web: 'hours', formatted: 'Hours', defaultValue: [] },
  { api: 'caller_types', web: 'callerTypes', formatted: 'Caller Types', defaultValue: [] },
  {
    api: 'use_business_hours',
    web: 'useBusinessHours',
    formatted: 'Use Business Hours',
    defaultValue: false,
  },
  {
    api: 'first_contact_only',
    web: 'firstContactOnly',
    formatted: 'First Contact Only',
    defaultValue: false,
  },
  {
    api: 'include_non_prospects',
    web: 'includeNonProspects',
    formatted: 'Include Non-Prospects',
    defaultValue: false,
  },
  {
    api: 'prospects_by_create_date',
    web: 'prospectsByCreateDate',
    formatted: 'Prospects By Create Date',
    defaultValue: false,
  },
  {
    api: 'sort_by_percentage',
    web: 'sortByPercentage',
    formatted: 'Sort by Percentage',
    defaultValue: false,
  },
  {
    api: 'cumulative_response_time',
    web: 'cumulativeResponseTime',
    formatted: 'Show Cumulative',
    defaultValue: true,
  },
  {
    api: 'property_attribute_ids',
    web: 'propertyAttributeIds',
    formatted: 'Property Attributes',
    defaultValue: [],
  },
  {
    api: 'properties_by_attributes',
    web: 'propertiesByAttributes',
    formatted: 'Properties By Attributes',
    defaultValue: [],
  },
  {
    api: 'sources_by_attributes',
    web: 'sourcesByAttributes',
    formatted: 'Sources by Attributes',
    defaultValue: [],
  },
  {
    api: 'show_contact_types',
    web: 'showContactTypes',
    formatted: 'Show Contact Types',
    defaultValue: false,
  },
  {
    api: 'show_applications',
    web: 'showApplications',
    formatted: 'Show Applications',
    defaultValue: false,
  },
  {
    api: 'date_by',
    web: 'dateBy',
    formatted: 'Date By',
    defaultValue: 'event',
  },

  // these should not be filters, but rather user actions within a reducer
  { api: 'download_csv', web: 'downloadCsv', formatted: 'Download CSV' },
  { api: 'share_report', web: 'shareReport', formatted: 'Share Report' },
  { api: 'save_report', web: 'saveReport', formatted: 'Save Report' },
]

export const REPORT_NAMES = {
  RENEWAL: 'renewal',
  AD_SPEND: 'ad-spend',
  APPOINTMENTS: 'appointments',
  APPOINTMENTS_UPCOMING: 'appointments-upcoming',
  ACTIVITY: 'activity',
  TRAFFIC: 'traffic',
}

export const REPORT_FILTER_MAP = [
  {
    name: REPORT_NAMES.AD_SPEND,
    path: '/ad-spend',
    defaultFilters: ['dateRange', 'propertyIds'],
    additionalFilters: ['sources', 'includeNonProspects'],
    groupByOptions: ['paid', 'combined'],
  },
  {
    name: REPORT_NAMES.RENEWAL,
    path: '/renewals',
    defaultFilters: ['dateRange', 'propertyIds'],
    additionalFilters: [],
  },
  {
    name: REPORT_NAMES.APPOINTMENTS,
    path: '/appointments',
    defaultFilters: ['dateRange', 'propertyIds', 'groupBy'],
    additionalFilters: ['agentIds', 'sources'],
    groupByOptions: ['property', 'source', 'agent'],
  },
  {
    name: REPORT_NAMES.APPOINTMENTS_UPCOMING,
    path: '/appointments',
    defaultFilters: ['dateRangeFuture', 'propertyIds', 'groupBy'],
    additionalFilters: ['agentIds', 'sources'],
    groupByOptions: ['property', 'source', 'agent'],
  },
  {
    name: REPORT_NAMES.ACTIVITY,
    path: '/activity',
    defaultFilters: ['dateRange', 'propertyIds'],
    additionalFilters: ['includeNonProspects', 'prospectsByCreateDate'],
  },
  {
    name: REPORT_NAMES.TRAFFIC,
    path: '/v2/traffic',
    defaultFilters: ['dateRange', 'propertyIds'],
    additionalFilters: ['agentIds', 'sources', 'contacts'],
  },
]
