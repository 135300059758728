import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { green, status__danger_red } from '_v2/common/styles/brandColors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '300px',
      paddingBottom: '20px',
    },

    root__loader_fallback: {
      height: '100vh',
    },

    // ensures loader will position in middle of view
    root__container: {
      height: 'inherit',
      paddingTop: '20px',
    },
    root__alignment_container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root__alignment_container_child: {
      maxWidth: '50%',
    },
    root__alignment_container_child_top: {
      alignSelf: 'flex-start',
    },
    root__alignment_container_child_bottom: {
      alignSelf: 'flex-end',
    },

    icon__success: {
      color: green,
      paddingBottom: '10px',
      // width: '40px',
      // height: '40px',
    },
    icon__error: {
      color: status__danger_red,
      paddingBottom: '10px',
      // width: '40px',
      // height: '40px',
    },

    // custom linear progress bar
    '@keyframes fill': {
      '0%': { width: '0%' },
      '100%': { width: '100%' },
    },
    linear_progress_bar: {
      height: '5px',
      width: '100%',
    },
    linear_progress_bar__animation_err: {
      animation: '$fill 12s linear',
      height: '100%',
      backgroundColor: status__danger_red,
    },
    linear_progress_bar__animation_success: {
      animation: '$fill 5s linear',
      height: '100%',
      backgroundColor: green,
    },
    link__support: {
      textDecoration: 'none',
    },
    icon__beta: {
      width: '40px',
      height: '40px',
    },
  })
)

export default useStyles
