export const DEMOGRAPHICS_REPORT_QUERY = `
query DemographicsReport($input: GetDemographicsReportInput!) {
  demographicsReport(input: $input) {
    total {
      property
      female
      male
      genderUnknown
      total
      totalFemaleRatio
      totalMaleRatio
      totalGenderUnknownRatio
      combinedRatio
    }
    properties {
      female
      male
      genderUnknown
      total
      totalFemaleRatio
      totalMaleRatio
      totalGenderUnknownRatio
      combinedRatio
      ageFemaleRatio
      ageMaleRatio
      ageGenderUnknownRatio
      property
      dataByAge {
        female
        male
        genderUnknown
        total
        totalFemaleRatio
        totalMaleRatio
        totalGenderUnknownRatio
        combinedRatio
        ageFemaleRatio
        ageMaleRatio
        ageGenderUnknownRatio
        ageBucket
      }
    }
  }
}
`

export const EXPORT_PDF_DEMOGRAPHICS_REPORT_MUTATION = `
mutation ExportDemographicsReport($input: ExportDemographicsReportInput!) {
  exportDemographicsReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_DEMOGRAPHICS_REPORT_MUTATION = `
mutation ExportDemographicsReport($input: ExportDemographicsReportInput!) {
  exportDemographicsReport(input: $input) {
    csvBase64
  }
}
`

export const SHARE_DEMOGRAPHICS_REPORT_MUTATION = `
mutation ShareDemographicsReport($input: ShareDemographicsReportInput!) {
  ShareDemographicsReport(input: $input) {
    response
  }
}
`

export const SAVE_DEMOGRAPHICS_REPORT_MUTATION = `
mutation SaveDemographicsReport($input: SaveDemographicsReportInput!) {
  saveDemographicsReport(input: $input) {
    savedReportId
  }
}
`
