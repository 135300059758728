import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as colors from '_v2/common/styles/brandColors'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: colors.gray_ultra_light,
    },
    paper: {
      margin: 'auto',
      maxWidth: '600px',
      minHeight: '200px',
      borderTopStyle: 'solid',
      borderTopColor: 'red',
    },
    content: {
      padding: '20px',
      marginRight: 'auto',
      textAlign: 'left',
    },
    btn: {
      backgroundColor: 'inherit',
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.blue_light,
      },
    },
  })
)

export default useStyles
