import { getAll, get, set, remove } from 'tiny-cookie'
import { Cookie } from './'

const listCookies = () => {
  return getAll()
}

const getCookie = (name: string): Cookie | null => {
  return get(name)
}

const setCookie = (data: Cookie) => {
  return data.options ? set(data.name, data.value, data.options) : set(data.name, data.value)
}

/**
 * @param name cookie name as string
 * @param domain domain under which a group of cookies fall under.  if provided, it will remove all cookies tied to that domain.
 */
const removeCookie = (name: string, domain?: string) => {
  return domain ? remove(name, { domain }) : remove(name)
}

export { listCookies, getCookie, setCookie, removeCookie }
