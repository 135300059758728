import config from '_v4/config'
import {
  ACTIVITY_REPORT_QUERY,
  EXPORT_CSV_ACTIVITY_REPORT_MUTATION,
  EXPORT_PDF_ACTIVITY_REPORT_MUTATION,
  SAVE_ACTIVITY_REPORT_MUTATION,
  SHARE_ACTIVITY_REPORT_MUTATION,
} from '_v4/services/api/utils/activityReportQuery'
import {
  MULTIPLE_PROSPECT_BATCH_DATA_QUERY,
  PROSPECT_BATCH_DATA_QUERY,
} from '_v4/services/api/utils/prospectBatchDataQuery'
import {
  EXPORT_CSV_OCCUPANCY_REPORT_MUTATION,
  EXPORT_PDF_OCCUPANCY_REPORT_MUTATION,
  OCCUPANCY_REPORT_QUERY,
  SAVE_OCCUPANCY_REPORT_MUTATION,
  SHARE_OCCUPANCY_REPORT_MUTATION,
} from '_v4/services/api/utils/occupancyReportQuery'
import {
  EXPORT_CSV_TOUR_ENGAGEMENT_REPORT_MUTATION,
  EXPORT_PDF_TOUR_ENGAGEMENT_REPORT_MUTATION,
  SAVE_TOUR_ENGAGEMENT_REPORT_MUTATION,
  SHARE_TOUR_ENGAGEMENT_REPORT_MUTATION,
  TOUR_ENGAGEMENT_REPORT_QUERY,
} from '_v4/services/api/utils/tourEngagementReportQuery'
import {
  DEMOGRAPHICS_REPORT_QUERY,
  EXPORT_CSV_DEMOGRAPHICS_REPORT_MUTATION,
  EXPORT_PDF_DEMOGRAPHICS_REPORT_MUTATION,
  SAVE_DEMOGRAPHICS_REPORT_MUTATION,
  SHARE_DEMOGRAPHICS_REPORT_MUTATION,
} from '_v4/services/api/utils/demographicsReportQuery'
import {
  EXPORT_CSV_ID_VERIFICATION_REPORT_MUTATION,
  EXPORT_PDF_ID_VERIFICATION_REPORT_MUTATION,
  ID_VERIFICATION_REPORT_QUERY,
  SAVE_ID_VERIFICATION_REPORT_MUTATION,
  SHARE_ID_VERIFICATION_REPORT_MUTATION,
} from './utils/idVerificationReportQuery'
import { TOURS_GATED_PROPERTIES_QUERY } from './utils/toursGatedPropertiesQuery'
import { GATED_PROPERTIES_QUERY } from './utils/gatedPropertiesQuery'
import { ADDRESS_BOOK_QUERY } from './utils/addressBookQuery'
import {
  EXPORT_CSV_TOUR_TYPE_REPORT_MUTATION,
  EXPORT_PDF_TOUR_TYPE_REPORT_MUTATION,
  SAVE_TOUR_TYPE_REPORT_MUTATION,
  SHARE_TOUR_TYPE_REPORT_MUTATION,
  TOUR_TYPE_REPORT_QUERY,
} from './utils/tourTypeReportQuery'
import { USER_DATA_QUERY } from './utils/userDataQuery'
import {
  EXPORT_CSV_MTA_REPORT_MUTATION,
  EXPORT_PDF_MTA_REPORT_MUTATION,
  MTA_REPORT_QUERY,
  SAVE_MTA_REPORT_MUTATION,
  SHARE_MTA_REPORT_MUTATION,
} from '_v4/services/api/utils/mtaReportQuery'
import {
  CALL_INTELLIGENCE_AGENT_REPORT_QUERY,
  CALL_INTELLIGENCE_INTENT_REPORT_QUERY,
  CALL_INTELLIGENCE_AGENT_THREAD_IDS_QUERY,
  EXPORT_CSV_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
  EXPORT_CSV_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
  EXPORT_PDF_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
  EXPORT_PDF_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
  SAVE_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
  SAVE_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
  SHARE_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
  SHARE_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
} from './utils/callIntelligenceReportQuery'
import {
  EXPORT_CSV_CALL_TRANSCRIPT_MUTATION,
  EXPORT_PDF_CALL_TRANSCRIPT_MUTATION,
  GET_CALL_TRANSCRIPT_QUERY,
  SAVE_CALL_TRANSCRIPT_MUTATION,
  SHARE_CALL_TRANSCRIPT_MUTATION,
} from './utils/getCallTranscriptQuery'
import { GET_CALL_LOG_QUERY } from './utils/getCallLogQuery'
import { ALL_PROPERTIES_QUERY } from './utils/propertiesQuery'
import { ALL_AGENTS_QUERY } from './utils/agentsQuery'
import { MARKETING_SOURCES_QUERY } from './utils/marketingSourcesQuery'
import { COMPANY_QUERY } from './utils/companyQuery'
import { TEAMS_MESSAGING_MUTATION } from './utils/teamsMessagingQuery'

// comments indicate the host url if different from Analytics API
const { ANALYTICS_GRAPHQL_API, SYNDICATION_AUTH_API, CRM_API, ANALYTICS_WEB, CRM_WEB, ADMIN_WEB } =
  config

// auth
export const AUTH_ENDPOINTS = {
  LOGIN: { host: CRM_API, url: `/auth/validate`, method: 'POST' },
  LOGOUT: {
    host: SYNDICATION_AUTH_API,
    url: `/logout?redirect_url=${ANALYTICS_WEB}`,
    method: 'REDIRECT',
  },
  RESET_PW: { host: CRM_API, url: `/auth/password/forgot`, method: 'POST' },
  REFRESH_TOKEN: { host: CRM_API, url: `/auth/refresh`, method: 'POST' },
  CRM_TRANSFER: {
    host: CRM_WEB,
    url: `/autologin?token={accessToken}`,
    method: 'REDIRECT',
  },
  ADMIN_TRANSFER: {
    host: ADMIN_WEB,
    url: `/login?accessToken={accessToken}`,
    method: 'REDIRECT',
  },
}

// GraphQL endpoints
export const ANALYTICS_GRAPHQL_ENDPOINTS = {
  // Generic data
  ADDRESS_BOOK: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: ADDRESS_BOOK_QUERY,
    method: 'POST',
  },
  AGENTS: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: ALL_AGENTS_QUERY,
    method: 'POST',
  },
  COMPANY: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: COMPANY_QUERY,
    method: 'POST',
  },
  GATED_PROPERTIES: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: GATED_PROPERTIES_QUERY,
    method: 'POST',
  },
  MARKETING_SOURCES: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: MARKETING_SOURCES_QUERY,
    method: 'POST',
  },
  MULTIPLE_PROSPECT_BATCH_DATA: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: MULTIPLE_PROSPECT_BATCH_DATA_QUERY,
    method: 'POST',
  },
  PROPERTIES: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: ALL_PROPERTIES_QUERY,
    method: 'POST',
  },
  PROSPECT_BATCH_DATA: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: PROSPECT_BATCH_DATA_QUERY,
    method: 'POST',
  },
  TOURS_GATED_PROPERTIES: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: TOURS_GATED_PROPERTIES_QUERY,
    method: 'POST',
  },
  USER_DATA: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: USER_DATA_QUERY,
    method: 'POST',
  },

  // Teams webhook
  ANALYTICS_TEAMS_MESSAGING: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    mutation: TEAMS_MESSAGING_MUTATION,
    method: 'POST',
  },

  // Reports alphabetically
  ACTIVITY_CREATED: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: ACTIVITY_REPORT_QUERY,
    method: 'POST',

    exportPdfMutation: EXPORT_PDF_ACTIVITY_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_ACTIVITY_REPORT_MUTATION,
    saveMutation: SAVE_ACTIVITY_REPORT_MUTATION,
    shareMutation: SHARE_ACTIVITY_REPORT_MUTATION,
  },
  ACTIVITY_EVENT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: ACTIVITY_REPORT_QUERY,
    method: 'POST',

    exportPdfMutation: EXPORT_PDF_ACTIVITY_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_ACTIVITY_REPORT_MUTATION,
    saveMutation: SAVE_ACTIVITY_REPORT_MUTATION,
    shareMutation: SHARE_ACTIVITY_REPORT_MUTATION,
  },
  CALL_INTELLIGENCE_AGENT_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: CALL_INTELLIGENCE_AGENT_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
    saveMutation: SAVE_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
    shareMutation: SHARE_CALL_INTELLIGENCE_AGENT_REPORT_MUTATION,
  },
  CALL_INTELLIGENCE_THREAD_IDS: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: CALL_INTELLIGENCE_AGENT_THREAD_IDS_QUERY,
    method: 'POST',
  },
  CALL_INTELLIGENCE_INTENT_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: CALL_INTELLIGENCE_INTENT_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
    saveMutation: SAVE_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
    shareMutation: SHARE_CALL_INTELLIGENCE_INTENT_REPORT_MUTATION,
  },
  CALL_LOG_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: GET_CALL_LOG_QUERY,
    method: 'POST',
  },
  CALL_TRANSCRIPT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: GET_CALL_TRANSCRIPT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_CALL_TRANSCRIPT_MUTATION,
    exportCsvMutation: EXPORT_CSV_CALL_TRANSCRIPT_MUTATION,
    saveMutation: SAVE_CALL_TRANSCRIPT_MUTATION,
    shareMutation: SHARE_CALL_TRANSCRIPT_MUTATION,
  },
  DEMOGRAPHICS_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: DEMOGRAPHICS_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_DEMOGRAPHICS_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_DEMOGRAPHICS_REPORT_MUTATION,
    saveMutation: SAVE_DEMOGRAPHICS_REPORT_MUTATION,
    shareMutation: SHARE_DEMOGRAPHICS_REPORT_MUTATION,
  },
  ID_VERIFICATION: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: ID_VERIFICATION_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_ID_VERIFICATION_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_ID_VERIFICATION_REPORT_MUTATION,
    saveMutation: SAVE_ID_VERIFICATION_REPORT_MUTATION,
    shareMutation: SHARE_ID_VERIFICATION_REPORT_MUTATION,
  },
  MTA_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: MTA_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_MTA_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_MTA_REPORT_MUTATION,
    saveMutation: SAVE_MTA_REPORT_MUTATION,
    shareMutation: SHARE_MTA_REPORT_MUTATION,
  },
  OCCUPANCY_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: OCCUPANCY_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_OCCUPANCY_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_OCCUPANCY_REPORT_MUTATION,
    saveMutation: SAVE_OCCUPANCY_REPORT_MUTATION,
    shareMutation: SHARE_OCCUPANCY_REPORT_MUTATION,
  },
  TOUR_ENGAGEMENT_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: TOUR_ENGAGEMENT_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_TOUR_ENGAGEMENT_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_TOUR_ENGAGEMENT_REPORT_MUTATION,
    saveMutation: SAVE_TOUR_ENGAGEMENT_REPORT_MUTATION,
    shareMutation: SHARE_TOUR_ENGAGEMENT_REPORT_MUTATION,
  },
  TOUR_TYPE_REPORT: {
    url: '',
    host: ANALYTICS_GRAPHQL_API,
    query: TOUR_TYPE_REPORT_QUERY,
    method: 'POST',
    exportPdfMutation: EXPORT_PDF_TOUR_TYPE_REPORT_MUTATION,
    exportCsvMutation: EXPORT_CSV_TOUR_TYPE_REPORT_MUTATION,
    saveMutation: SAVE_TOUR_TYPE_REPORT_MUTATION,
    shareMutation: SHARE_TOUR_TYPE_REPORT_MUTATION,
  },
}
