import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '600px',
      textAlign: 'center',
    },
    loader: {
      margin: 'auto',
    },
  })
)

export default useStyles
