import React from 'react'
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  ClickAwayListener,
  IconButton,
  Link,
} from '@material-ui/core'
import { Cancel, CloseOutlined } from '@material-ui/icons'

// types
import { ILoaderProps } from './types'

// styles
import useStyles from './styles'

export const LoaderResultError: React.FC<ILoaderProps> = ({ handleClose, timeout = 10000 }) => {
  const classes = useStyles()

  const [visible, setVisible] = React.useState<boolean>(true)
  const [countdown, setCountdown] = React.useState(timeout / 1000)

  const handleCloseDialog = () => {
    handleClose()
    setVisible(false)
  }

  // handle auto-close countdown
  React.useEffect(() => {
    let endDate = new Date()
    endDate.setDate(endDate.getSeconds() + countdown)

    const tick = () => {
      if (countdown !== 0) {
        setCountdown(lastCountdownValue => (lastCountdownValue = 0 ? 0 : lastCountdownValue - 1))
      } else {
        handleCloseDialog()
      }
    }

    let timer = setInterval(tick, 1000)
    return () => {
      clearInterval(timer)
    }
  })

  return (
    <>
      {visible && (
        <ClickAwayListener onClickAway={handleClose}>
          <Dialog open={visible}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
            <DialogContent className={classes.root}>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Cancel fontSize="large" className={classes.icon__error} />
                <Typography variant={'body2'}>{`Oh no!`}</Typography>
                <Typography
                  variant={'caption'}
                  paragraph
                >{`An error occurred and we've been notified.`}</Typography>
                <Typography variant={'caption'}>
                  {`Contact `}
                  <Link
                    href="mailto: knockrentals@support.com"
                    className={classes.link__support}
                    underline="none"
                  >
                    support
                  </Link>
                  {` for assistance.`}
                </Typography>
              </Grid>
            </DialogContent>
            <div className={classes.linear_progress_bar}>
              <div className={classes.linear_progress_bar__animation_err} />
            </div>
          </Dialog>
        </ClickAwayListener>
      )}
    </>
  )
}
