import React from 'react'
import ErrorFallback from './ErrorFallback'

export interface IErrorBoundaryProps {
  /** Content to wrap. */
  children: NonNullable<React.ReactNode>

  /** Affected user. */
  user: any

  /** Component to display on error */
  displayComponent?: React.ReactNode

  /** Callback fired when an error is received. */
  onCatch?: (user: {}, error: Error, errorInfo: any) => void
}

export type ErrorBoundaryState = {
  error: Error | null
  errorInfo: any
}

/** Wraps a component and catches errors thrown within its React tree. */
class ErrorBoundary extends React.Component<IErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    error: null,
    errorInfo: null,
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!this.props.onCatch) {
      console.error(`error: ${error}`)
      console.error(`info: ${errorInfo}`)
    } else {
      this.props.onCatch(this.props.user && {}, error, errorInfo)
    }

    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { error } = this.state

    if (!error) {
      return this.props.children
    }
    return <>{this.props.displayComponent ? this.props.displayComponent : <ErrorFallback />}</>
  }
}

export default ErrorBoundary
