import React from 'react'
// components
import { ThemeProvider } from '@material-ui/styles'
import { AuthProvider } from './authContext'
import { UserProvider } from './userContext'
import { PropertyProvider } from './properties'
import { FilterProvider } from './FilterProvider'
import { AlertProvider } from './alerts'

import ErrorBoundary from '_v2/common/components/ErrorBoundary'
// services

// styles
import theme from '_v2/common/theme'
// v2 provider:
import { ReportProvider } from './ReportProvider'

function AppProviders({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AlertProvider>
          <AuthProvider>
            <UserProvider>
              <ReportProvider>
                <FilterProvider>
                  <PropertyProvider>{children}</PropertyProvider>
                </FilterProvider>
              </ReportProvider>
            </UserProvider>
          </AuthProvider>
        </AlertProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default AppProviders
