import React from 'react'
import {
  Typography,
  Grid,
  Dialog,
  DialogContent,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core'
import { CheckCircle, CloseOutlined } from '@material-ui/icons'

// types
import { ILoaderProps } from './types'

// styles
import useStyles from './styles'

export const LoaderResultSuccess: React.FC<ILoaderProps> = ({
  message,
  handleClose,
  timeout = 4000,
}) => {
  const classes = useStyles()

  const [visible, setVisible] = React.useState<boolean>(true)
  const [countdown, setCountdown] = React.useState(timeout / 1000)

  const handleCloseDialog = () => {
    handleClose()
    setVisible(false)
  }

  // handle auto-close countdown
  React.useEffect(() => {
    let startDate = new Date()
    startDate.setDate(startDate.getSeconds() + countdown)

    const tick = () => {
      if (countdown !== 0) {
        setCountdown(lastCountdownValue => (lastCountdownValue = 0 ? 0 : lastCountdownValue - 1))
      } else {
        handleCloseDialog()
      }
    }

    let timer = setInterval(tick, 1000)
    return () => {
      clearInterval(timer)
    }
  })

  return (
    <>
      {visible && (
        <ClickAwayListener onClickAway={handleClose}>
          <Dialog open={visible}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
            <DialogContent className={classes.root}>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <CheckCircle fontSize="large" className={classes.icon__success} />
                {message ? <Typography variant={'body2'}>{message}</Typography> : null}
              </Grid>
            </DialogContent>
            <div className={classes.linear_progress_bar}>
              <div className={classes.linear_progress_bar__animation_success} />
            </div>
          </Dialog>
        </ClickAwayListener>
      )}
    </>
  )
}
