import { gql } from '@apollo/client'

export const GET_CUSTOM_ATTRIBUTES = gql`
  query GetCustomAttributes {
    getCustomAttributes {
      attributeKey
      attributeValueList
    }
  }
`
