export const TOUR_TYPE_REPORT_QUERY = `
query TourTypeReport($input: GetTourTypeReportInput!) {
  tourTypeReport(input: $input) {
    total {
      property
      visits
      applications
      leases
      applicationsPerVisit
      leasesPerVisit
    }
    properties {
      visits
      applications
      leases
      applicationsPerVisit
      leasesPerVisit
      property
      propertyId
      visitTypeData {
        visits
        applications
        leases
        applicationsPerVisit
        leasesPerVisit
        visitType
      }
    }
    isYardiCustomer
  }
}
`

export const EXPORT_PDF_TOUR_TYPE_REPORT_MUTATION = `
mutation ExportTourTypeReport($input: ExportTourTypeReportInput!) {
  exportTourTypeReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_TOUR_TYPE_REPORT_MUTATION = `
mutation ExportTourTypeReport($input: ExportTourTypeReportInput!) {
  exportTourTypeReport(input: $input) {
    csvBase64
  }
}
`

export const SAVE_TOUR_TYPE_REPORT_MUTATION = `
mutation SaveTourTypeReport($input: SaveTourTypeReportInput!) {
  saveTourTypeReport(input: $input) {
    savedReportId
  }
}
`

export const SHARE_TOUR_TYPE_REPORT_MUTATION = `
mutation ShareTourTypeReport($input: ShareTourTypeReportInput!) {
  ShareTourTypeReport(input: $input) {
    response
  }
}
`
