export const TOUR_ENGAGEMENT_REPORT_QUERY = `
query TourEngagementReport($input: GetTourEngagementReportInput!) {
  tourEngagementReport(input: $input) {
    total {
      property
      totalVisits
      toursVisits
      toursVisitsWithShow
      toursLeases
      toursVisitsToLeasesRatio
      otherVisits
      otherVisitsWithShow
      otherLeases
      otherVisitsToLeasesRatio
      tourStart
      meetAndGreet
      idScan
      walkthrough
      quote
      tourFinish
      showRatio
      tourEngagement
      tourStartRatio
      meetAndGreetRatio
      idScanRatio
      walkthroughRatio
      quoteRatio
      tourFinishRatio
    }
    properties{
      property
      totalVisits
      toursVisits
      toursVisitsWithShow
      toursLeases
      toursVisitsToLeasesRatio
      otherVisits
      otherVisitsWithShow
      otherLeases
      otherVisitsToLeasesRatio
      tourStart
      meetAndGreet
      idScan
      walkthrough
      quote
      tourFinish
      showRatio
      tourEngagement
      tourStartRatio
      meetAndGreetRatio
      idScanRatio
      walkthroughRatio
      quoteRatio
      tourFinishRatio
      agentData {
        agent
        totalVisits
        toursVisits
        toursVisitsWithShow
        toursLeases
        toursVisitsToLeasesRatio
        otherVisits
        otherVisitsWithShow
        otherLeases
        otherVisitsToLeasesRatio
        tourStart
        meetAndGreet
        idScan
        walkthrough
        quote
        tourFinish
        showRatio
        tourEngagement
        tourStartRatio
        meetAndGreetRatio
        idScanRatio
        walkthroughRatio
        quoteRatio
        tourFinishRatio
      }
    }
  }
}
`

export const EXPORT_PDF_TOUR_ENGAGEMENT_REPORT_MUTATION = `
mutation ExportTourEngagementReport($input: ExportTourEngagementReportInput!) {
  exportTourEngagementReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_TOUR_ENGAGEMENT_REPORT_MUTATION = `
mutation ExportTourEngagementReport($input: ExportTourEngagementReportInput!) {
  exportTourEngagementReport(input: $input) {
    csvBase64
  }
}
`

export const SHARE_TOUR_ENGAGEMENT_REPORT_MUTATION = `
mutation ShareTourEngagementReport($input: ShareTourEngagementReportInput!) {
  ShareTourEngagementReport(input: $input) {
    response
  }
}
`

export const SAVE_TOUR_ENGAGEMENT_REPORT_MUTATION = `
mutation SaveTourEngagementReport($input: SaveTourEngagementReportInput!) {
  saveTourEngagementReport(input: $input) {
    savedReportId
  }
}
`
