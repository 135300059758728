import { IProperty } from '_v2/contexts/properties/types'
import { Agent } from '_v2/contexts/models'

import fakeProperties from './fake_property_names.json'
import fakeAgents from './fake_agent_names.json'

// #demo
interface DemoTransform {
  fakeName: string
  usedNames: string[]
}

// PROPERTIES

// Currently used in PropertyProvider. This will populate the filter dropdown with fake property names.
// Can't reuse for individual reports because of the wide range in property schemas that come back in api responses.
// Individual fake name transforms have been added to each report's service to handle the various property schemas.
export const setDemoProperties = (properties: IProperty[]): IProperty[] => {
  let usedNames: string[] = []

  properties.forEach(p => {
    const transform = getFakePropertyName(usedNames)
    // update usedName list to ensure uniqueness
    usedNames = [...transform.usedNames]

    // set propertyName to fake name
    p.propertyName = transform.fakeName
  })
  return properties
}

// reused through v1 and v2 reports
export const getFakePropertyName = (usedNames: string[]): DemoTransform => {
  const { properties } = fakeProperties

  let transform: DemoTransform = {
    fakeName: '',
    usedNames,
  }

  for (let p of properties) {
    if (!usedNames.includes(p.propertyName)) {
      transform.fakeName = p.propertyName
      transform.usedNames = [...usedNames, p.propertyName]
      break
    }
  }
  return transform
}

// AGENTS

// Currently used in AgentProvider. This will populate the filter dropdown with fake agent names.
// Can't reuse for individual reports because of the wide range in agent schemas that come back in api responses.
// Individual fake name transforms have been added to each report's service to handle the various agent schemas.
export const setDemoAgents = (agents: Agent[]): Agent[] => {
  let usedNames: string[] = []

  agents.forEach(a => {
    const transform = getFakeAgentName(usedNames)
    // update usedName list to ensure uniqueness
    usedNames = [...transform.usedNames]

    // set propertyName to fake name
    a.name = transform.fakeName
  })
  return agents
}

// reused through v1 and v2 reports
export const getFakeAgentName = (usedNames: string[]): DemoTransform => {
  const { agents } = fakeAgents

  let transform: DemoTransform = {
    fakeName: '',
    usedNames,
  }

  for (let a of agents) {
    if (!usedNames.includes(a.name)) {
      transform.fakeName = a.name
      transform.usedNames = [...usedNames, a.name]
      break
    }
  }
  return transform
}

export const getFakeFloorPlanName = (usedNames: string[]): DemoTransform => {
  // TODO Update with dummy data for fakeFloorplanNames ANA-1692
  const { agents } = fakeAgents

  let transform: DemoTransform = {
    fakeName: '',
    usedNames,
  }

  for (let a of agents) {
    if (!usedNames.includes(a.name)) {
      transform.fakeName = a.name
      transform.usedNames = [...usedNames, a.name]
      break
    }
  }
  return transform
}
