import { differenceInHours, toDate } from 'date-fns'
import { Token, DecodedToken } from '_v2/services/token'
import config from '_v2/config'

export const getTokens = (): Token | undefined => {
  const accessToken = window.localStorage.getItem(config.TOKEN_ACCESS)
  const refreshToken = window.localStorage.getItem(config.TOKEN_REFRESH)

  return accessToken && refreshToken
    ? {
        accessToken,
        refreshToken,
      }
    : undefined
}

export const decodeToken = (token: string): DecodedToken => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// an "expired" token = access_token that is less than 72 hrs away from expiring
export const isTokenExpired = (token: string, refreshToken: string): boolean => {
  const decodedToken: DecodedToken = decodeToken(token)

  const expDate = toDate(new Date(decodedToken.exp))
  const today = toDate(new Date())

  return differenceInHours(expDate, today) < config.TOKEN_REFRESH_HOURS
}
