export const PROSPECT_BATCH_DATA_QUERY = `
query GetProspectBatchData($prospectIds: [Int!]!, $propertyId: Float!) {
  getProspectBatchData(prospectIds: $prospectIds, propertyId: $propertyId) {
    propertyId
    leasingTeamId
    accessToken
    batchId
    prospectCount
  }
}
`
export const MULTIPLE_PROSPECT_BATCH_DATA_QUERY = `
query GetMultipleProspectBatchData($prospectIds: [Int!]!) {
  getMultipleProspectBatchData(prospectIds: $prospectIds) {
    propertyId
    leasingTeamId
    accessToken
    batchId
    prospectCount
  }
}
`
