import {
  ApolloClient,
  DocumentNode,
  TypedDocumentNode,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { useEffect, useState } from 'react'
interface Props<TransformedData, QueryResponse, QueryParams> {
  graphQLQuery: TypedDocumentNode | DocumentNode
  transformFetchData: (data?: QueryResponse | null) => TransformedData | null
  params: QueryParams
  skip?: boolean
  client?: ApolloClient<any>
  fetchPolicy?: WatchQueryFetchPolicy
}

const useFetchGraphQL = <QueryResponse, TransformedData, QueryParams>({
  graphQLQuery,
  transformFetchData,
  params,
  skip = false,
  client,
  fetchPolicy,
}: Props<TransformedData, QueryResponse, QueryParams>) => {
  const [hasExecutedQuery, setHasExecutedQuery] = useState(false)
  const { loading, error, data } = useQuery<QueryResponse, QueryParams>(graphQLQuery, {
    variables: {
      ...params,
    },
    skip: skip,
    errorPolicy: 'all',
    client: client,
    ...(fetchPolicy ? { fetchPolicy } : {}),
  })
  useEffect(() => {
    setHasExecutedQuery(true)
  }, [])

  return {
    isError: !!error,
    data: loading ? null : transformFetchData(data),
    errorMessage: error,
    isLoading: hasExecutedQuery ? loading : undefined,
  }
}

export default useFetchGraphQL
