export const OCCUPANCY_REPORT_QUERY = `
query OccupancyReport($input: GetOccupancyReportInput!) {
  occupancyReport(input: $input) {
    total {
      totalUnits
      totalOccupied
      occupiedRatio
      rentedVacant
      unrentedVacant
      totalVacant
      vacantRatio
      rentedNotice
      unrentedNotice
      totalNotice
      noticeRatio
      totalToRent
      toRentRatio
      toRentRatioVsVacantRatio
    }
    properties {
      property
      totalUnits
      totalOccupied
      occupiedRatio
      rentedVacant
      unrentedVacant
      totalVacant
      vacantRatio
      rentedNotice
      unrentedNotice
      totalNotice
      noticeRatio
      totalToRent
      toRentRatio
      toRentRatioVsVacantRatio
      floorplanData {
        floorplan
        bedrooms
        area
        totalUnits
        totalOccupied
        occupiedRatio
        rentedVacant
        unrentedVacant
        totalVacant
        vacantRatio
        rentedNotice
        unrentedNotice
        totalNotice
        noticeRatio
        totalToRent
        toRentRatio
        toRentRatioVsVacantRatio
      }
    }
  }
}
`
export const EXPORT_PDF_OCCUPANCY_REPORT_MUTATION = `
mutation ExportOccupancyReport($input: ExportOccupancyReportInput!) {
  exportOccupancyReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_OCCUPANCY_REPORT_MUTATION = `
mutation ExportOccupancyReport($input: ExportOccupancyReportInput!) {
  exportOccupancyReport(input: $input) {
    csvBase64
  }
}
`

export const SAVE_OCCUPANCY_REPORT_MUTATION = `
mutation SaveOccupancyReport($input: SaveOccupancyReportInput!) {
  saveOccupancyReport(input: $input) {
    savedReportId
  }
}
`

export const SHARE_OCCUPANCY_REPORT_MUTATION = `
mutation ShareOccupancyReport($input: ShareOccupancyReportInput!) {
  ShareOccupancyReport(input: $input) {
    response
  }
}
`
