import React, { SyntheticEvent } from 'react'
import { Container, Typography, Paper, Button } from '@material-ui/core'
import useStyles from './error-fallback.styles'
import { clearAllItems } from '_v2/common/services/localStorage'

type ErrorFallbackProps = {
  reload?: boolean
  errorCode?: string
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ reload, errorCode }) => {
  const classes = useStyles({})

  const handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault()

    clearAllItems()
    window.location.href = '/login'
  }

  return (
    <Container
      className={classes.root}
      style={
        reload
          ? {
              marginTop: '60px',
            }
          : {
              height: '100vh',
              width: '100vw',
            }
      }
    >
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <Typography align="left" variant="h5">
            Looks like we're having some issues.
          </Typography>
          <Typography align="left" variant="caption" paragraph>
            Error Code: {errorCode || '500'}
          </Typography>
          <Typography align="left" variant="body2" paragraph>
            An error has occurred and we've been notified.
          </Typography>
          <Typography align="left" variant="body2" paragraph>
            If you need immediate assistance, please reach out to our awesome support team. We'll
            get this fixed asap!
          </Typography>
          <br />
          <br />
          <Button
            onClick={reload ? () => window.location.reload(false) : handleOnClick}
            type="submit"
            variant="outlined"
            color="primary"
            className={classes.btn}
          >
            {reload ? 'Reload' : 'Go Back'}
          </Button>
        </div>
      </Paper>
    </Container>
  )
}

export default ErrorFallback
