import React, { FC, useEffect, createContext, useContext, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import { ReportMetadata, ActionTypes, Action, REPORT_METADATA } from '_v4/containers/Reports/models'

export interface ReportState {
  report: ReportMetadata | undefined
}

const initialState: ReportState = {
  report: undefined,
}

const reportReducer = (reportState: ReportState = initialState, action: Action): ReportState => {
  const { SET_REPORT, SET_ERROR } = ActionTypes

  switch (action.type) {
    case SET_REPORT:
      return {
        ...reportState,
        report: REPORT_METADATA.find((r: ReportMetadata) => r.key === action.key),
      }
    case SET_ERROR: {
      return { ...initialState }
    }
    default:
      throw new Error('unhandled report action type')
  }
}

const ReportContext = createContext<{
  reportState: ReportState
  reportDispatch: (action: Action) => void
}>({ reportState: initialState, reportDispatch: () => {} })

const ReportProvider: FC = ({ children }) => {
  const location = useLocation()
  const [reportState, reportDispatch] = useReducer(reportReducer, initialState)
  const value = { reportState, reportDispatch }
  const { SET_REPORT } = ActionTypes

  const loadReportData = () => {
    const selectedReport = REPORT_METADATA.find((report) => report.path === location.pathname)
    if (location && selectedReport) {
      reportDispatch({ type: SET_REPORT, key: selectedReport.key })
    }
  }

  useEffect(() => {
    loadReportData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
}

const useReportContext = () => {
  const ctx = useContext(ReportContext)
  if (ctx === undefined) {
    throw new Error('useReportContext must be used within a ReportProvider.')
  }
  return ctx
}

export { ReportProvider, useReportContext }
