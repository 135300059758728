export interface FilterMetadata {
  key: FilterKeys
  title: FilterTitles
}
// use a Pick<> on individual report payloads to get a strongly
// typed Request object.  See Calls/models for example.
export interface FilterPayloadTypes {
  startDate: string
  endDate: string
  relativeDateRange: string
  propertyIds: number[]
  groupBy: GroupByTypes | string // allowing a string in case the company has custom group by attrs
  sources: string[]
  statuses: string[]
  days: number[]
  hours: number[]
  callerTypes: string[]
  viewBy: ViewByTypes | string // mta has both groupBy and viewBy
}

export enum FilterKeys {
  AGENTS = 'agentIds', // should be "agents". misleading name
  CALLER_TYPES = 'callerTypes',
  CONTACTS = 'contacts',
  CUMULATIVE_RESPONSE_TIME = 'cumulativeResponseTime',
  DATE_BY = 'dateBy',
  DATE_RANGE = 'dateRange',
  DATE_RANGE_FUTURE = 'dateRangeFuture',
  DAYS_HOURS = 'daysHours',
  FIRST_CONTACT_ONLY = 'firstContactOnly',
  GROUP_BY = 'groupBy',
  INCLUDE_NON_PROSPECTS = 'includeNonProspects',
  LEASING_TEAMS = 'leasingTeams',
  PROPERTY = 'property',
  PROPERTIES = 'propertyIds', // should be "properties". misleading name
  PROPERTIES_BY_ATTRIBUTES = 'propertiesByAttributes',
  PROPERTY_ATTRIBUTES = 'propertyAttributeIds', // should be "propertyAttributes". misleading name
  PROSPECTS_BY_CREATE_DATE = 'prospectsByCreateDate',
  SHOW_APPLICATIONS = 'showApplications',
  SHOW_CONTACT_TYPES = 'showContactTypes',
  SOURCES = 'sources',
  SOURCES_BY_ATTRIBUTES = 'sourcesByAttributes',
  STATUSES = 'statuses',
  USE_BUSINESS_HOURS = 'useBusinessHours',
  VIEW_BY = 'viewBy',
  VIEW_AS = 'viewAs',
  CALLER_TYPES_SELECT = 'callerType',
  AVERAGE_FRAUD_RATE = 'averageFraudRate',
  AVERAGE_FRAUD_COST = 'averageFraudCost',
  NOT_A_PROSPECT = 'notAProspect',
}

export enum FilterTitles {
  AGENTS = 'Agents',
  CALLER_TYPES = 'Caller Types',
  CONTACTS = 'Contacts',
  CUMULATIVE_RESPONSE_TIME = 'Show Cumulative',
  DATE_BY = 'Date By',
  DATE_RANGE = 'Date Range',
  DATE_RANGE_FUTURE = 'Date Range',
  DAYS_HOURS = 'Time Range',
  GROUP_BY = 'Group By',
  INCLUDE_NON_PROSPECTS = 'Include Non-Prospects',
  LEASING_TEAMS = 'Leasing Teams',
  PROPERTY = 'Property',
  PROPERTIES = 'Properties',
  PROPERTIES_BY_ATTRIBUTES = 'Properties by Attributes',
  PROPERTY_ATTRIBUTES = 'Properties Attributes',
  PROSPECTS_BY_CREATE_DATE = 'Prospects by Create Date',
  SHOW_APPLICATIONS = 'Show Applications',
  SHOW_CONTACT_TYPES = 'Show Contact Types',
  SOURCES = 'Sources',
  SOURCES_BY_ATTRIBUTES = 'Sources by Attributes',
  STATUSES = 'Statuses',
  USE_BUSINESS_HOURS = 'Use Business Hours',
  VIEW_BY = 'View By',
  CALLER_TYPES_SELECT = 'Caller Type',
  AVERAGE_FRAUD_RATE = 'Average Fraud Rate',
  AVERAGE_FRAUD_COST = 'Average Fraud Cost',
  NOT_A_PROSPECT = 'Not a Prospect',
}

// these are the standard group by's across companies.
// however, some companies are allowed to create custom groupBy's
// so we need to allow for any "string" value to extend the enum
export enum GroupByTypes {
  AGENT = 'agent',
  CALLER_TYPE = 'caller-type',
  DAY = 'day',
  HOUR = 'hour',
  PROPERTY = 'property',
  SOURCE = 'source',
  SOURCE_ATTRIBUTE = 'source-attribute',
  STATUS = 'status',
  INSERT_METHOD = 'insert-method',
}

export enum ViewByTypes {
  // MTA REPORT
  ALL = 'all',
  LEASE_CONVERSION = 'lease-conversion',
  VISIT_CONVERSION = 'visit-conversion',

  // CALLS_REPORT
  COUNT = 'COUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export const FILTER_METADATA: FilterMetadata[] = [
  { key: FilterKeys.AGENTS, title: FilterTitles.AGENTS },
  { key: FilterKeys.CALLER_TYPES, title: FilterTitles.CALLER_TYPES },
  { key: FilterKeys.CONTACTS, title: FilterTitles.CONTACTS },
  { key: FilterKeys.CUMULATIVE_RESPONSE_TIME, title: FilterTitles.CUMULATIVE_RESPONSE_TIME },
  { key: FilterKeys.DATE_RANGE, title: FilterTitles.DATE_RANGE },
  { key: FilterKeys.DATE_RANGE_FUTURE, title: FilterTitles.DATE_RANGE_FUTURE },
  { key: FilterKeys.DAYS_HOURS, title: FilterTitles.DAYS_HOURS },
  { key: FilterKeys.FIRST_CONTACT_ONLY, title: FilterTitles.PROSPECTS_BY_CREATE_DATE },
  { key: FilterKeys.GROUP_BY, title: FilterTitles.GROUP_BY },
  { key: FilterKeys.INCLUDE_NON_PROSPECTS, title: FilterTitles.INCLUDE_NON_PROSPECTS },
  { key: FilterKeys.LEASING_TEAMS, title: FilterTitles.LEASING_TEAMS },
  { key: FilterKeys.PROPERTY, title: FilterTitles.PROPERTY },
  { key: FilterKeys.PROPERTIES, title: FilterTitles.PROPERTIES },
  { key: FilterKeys.PROPERTIES_BY_ATTRIBUTES, title: FilterTitles.PROPERTIES_BY_ATTRIBUTES },
  { key: FilterKeys.PROPERTY_ATTRIBUTES, title: FilterTitles.PROPERTY_ATTRIBUTES },
  { key: FilterKeys.PROSPECTS_BY_CREATE_DATE, title: FilterTitles.PROSPECTS_BY_CREATE_DATE },
  { key: FilterKeys.SHOW_APPLICATIONS, title: FilterTitles.SHOW_APPLICATIONS },
  { key: FilterKeys.SHOW_CONTACT_TYPES, title: FilterTitles.SHOW_CONTACT_TYPES },
  { key: FilterKeys.SOURCES, title: FilterTitles.SOURCES },
  { key: FilterKeys.SOURCES_BY_ATTRIBUTES, title: FilterTitles.SOURCES_BY_ATTRIBUTES },
  { key: FilterKeys.STATUSES, title: FilterTitles.STATUSES },
  { key: FilterKeys.USE_BUSINESS_HOURS, title: FilterTitles.USE_BUSINESS_HOURS },
  { key: FilterKeys.VIEW_BY, title: FilterTitles.VIEW_BY },
  { key: FilterKeys.CALLER_TYPES_SELECT, title: FilterTitles.CALLER_TYPES_SELECT },
  { key: FilterKeys.DATE_BY, title: FilterTitles.DATE_BY },
  { key: FilterKeys.AVERAGE_FRAUD_RATE, title: FilterTitles.AVERAGE_FRAUD_RATE },
  { key: FilterKeys.AVERAGE_FRAUD_COST, title: FilterTitles.AVERAGE_FRAUD_COST },
  { key: FilterKeys.NOT_A_PROSPECT, title: FilterTitles.NOT_A_PROSPECT },
]
