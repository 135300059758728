import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { blue_dark, white } from '_v2/common/styles/brandColors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      height: '80px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 210,
      minHeight: 70,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    'modal__action-bar': {
      margin: '24px 0 -24px',
      paddingBottom: '0',
    },
    'modal__save-button': {
      order: 2,
      margin: '0 6px',
    },
    'modal__cancel-button': {
      order: 1,
      margin: '0 6px',
    },
    button__report_action: {
      color: white,
      backgroundColor: blue_dark.lightened_10,
      border: `2px solid ${blue_dark.lightened_10}`,
      '&:hover': {
        color: blue_dark.lightened_10,
        backgroundColor: 'transparent',
        border: `2px solid ${blue_dark.lightened_10}`,
      },
    },
  })
)

export default useStyles
