import { ApiResponse } from '_v2/common/services/api'

// start: base app object
export interface IProperty {
  id: number
  propertyName: string
}
// end: base app object

export type PropertySelectItem = {
  id: number
  propertyName: string
}

// start: provider types
export enum PropertyActions {
  SetAll = 'SET_ALL',
  SingleFilter = 'SINGLE_FILTER',
  Filter = 'FILTER',
  PropertyError = 'ERROR',
}

// end: provider types

// start: api response
export interface IPropertyApiResponse extends ApiResponse {
  properties: PropertyApiResponse[]
}

export type PropertyApiResponse = {
  community_id: string
  company_id: number
  id: number
  leasing_team_id: number
  name: string
  owning_manager_id: number
  property_type: string
  timezone: string
  total_units: number
}

export type PropertyGraphQLResponse = {
  id: number
  name: string
}

export type IToursGatedPropertiesApiResponse = {
  getToursGatedProperties: {
    properties: number[]
    toursGatedProperties: number[]
  }
}
// end: api response

// These props are for the menu opened by the mui <Select>
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
}
