// see axios config options: https://github.com/axios/axios
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import { toSnake, toCamel } from 'convert-keys'
import queryString from 'query-string'

import { logError, ERROR_MSG_HANDLED } from '_v2/services/error'
import { getTokens, isTokenExpired, Token } from '_v2/services/token'

import * as endpoints from './endpoints'
import config from '_v4/config'
import { ServerResponseData } from './models'

const client: AxiosInstance = axios.create({
  baseURL: config.ANALYTICS_GRAPHQL_API,
  timeout: config.CALL_TIMEOUT as unknown as number,
  timeoutErrorMessage: `Request timed out after ${config.ENV === 'local' ? 60 : 30} seconds.`,
  paramsSerializer: (params) => {
    return queryString.stringify(toSnake(params))
  },
})

// run before each request is made
client.interceptors.request.use(
  async (req: AxiosRequestConfig) => {
    // check token expiration
    await requestInterceptorValidateToken()
    const accessToken = window.localStorage.getItem(config.TOKEN_ACCESS)
    req.headers = { Authorization: `Bearer ${accessToken}` }

    const salesDemo = window.localStorage.getItem('sales_demo')
    if (salesDemo) {
      req.headers.demo = salesDemo
    }

    return { ...req }
  },
  (error) => {
    logError({
      data: error,
      message: `${ERROR_MSG_HANDLED} client.interceptors.request()`,
    })
    return Promise.reject(error)
  }
)

// run before each response hits business logic
client.interceptors.response.use(
  // any status code that lie within the range of 2xx cause this function to trigger
  (res: AxiosResponse) => {
    //TODO remove error handler when API implements status codes for error
    if (res.data.errors) {
      logError({
        data: res.data.errors[0].message,
        message: `${ERROR_MSG_HANDLED} client.interceptors.response()`,
      })
      return Promise.reject(res.data.errors[0].message)
    }

    if (res.config.responseType !== 'blob') {
      res.data = toCamel(res.data)
    }
    return { ...res }
  },
  // any status codes that falls outside the range of 2xx cause this function to trigger
  (error) => {
    const errorStatus = error?.response?.status
    const unAuthedStatuses = [401, 403]

    if (errorStatus) {
      if (unAuthedStatuses.includes(errorStatus)) {
        window.localStorage.clear()
        window.location.reload()
      }
    }
    logError({
      data: error,
      message: `${ERROR_MSG_HANDLED} client.interceptors.response()`,
    })
    return Promise.reject(error)
  }
)

/**
 * Request interceptor that validates the accessToken before each call.
 * Refreshes the accessToken if expired or about to be expired, then sets
 * local storage.
 */
const requestInterceptorValidateToken = async (): Promise<void> => {
  const tokens = getTokens()

  try {
    if (tokens) {
      const { accessToken, refreshToken } = tokens
      if (isTokenExpired(accessToken, refreshToken)) {
        // needs separate instance of axios to prevent doom loop
        const { REFRESH_TOKEN } = endpoints.AUTH_ENDPOINTS

        const { data }: ServerResponseData<Token> = await axios.get(
          `${config.CRM_API}${REFRESH_TOKEN.url}`,
          {
            data: {
              refreshToken,
            },
          }
        )

        window.localStorage.setItem(config.TOKEN_ACCESS, data.accessToken)
        window.localStorage.setItem(config.TOKEN_REFRESH, data.refreshToken)
      }
    }
    Promise.resolve()
  } catch (error) {
    logError({
      data: error as Error,
      message: `${ERROR_MSG_HANDLED} requestInterceptorValidateToken()`,
    })
    Promise.reject(error)
  }
}

export default client
