export const MTA_REPORT_QUERY = `
  query MultiTouchAttributionReport($input: GetMultiTouchAttributionReportInput!) {
    multiTouchAttributionReport(input: $input) {
      total {
        singleTouchProspects
        singleTouchLeases
        firstTouchProspects
        firstTouchLeases
        laterTouchProspects
        laterTouchLeases
        totalProspects
        totalLeases
        singleTouchConversions
        firstTouchConversions
        laterTouchConversions
        totalConversions
      }
      sourceData {
        singleTouchProspects
        singleTouchLeases
        firstTouchProspects
        firstTouchLeases
        laterTouchProspects
        laterTouchLeases
        totalProspects
        totalLeases
        singleTouchConversions
        firstTouchConversions
        laterTouchConversions
        totalConversions
        source
      }
    }
  }
`

export const EXPORT_PDF_MTA_REPORT_MUTATION = `
mutation ExportMultiTouchAttributionReport($input: ExportMultiTouchAttributionReportInput!) {
  exportMultiTouchAttributionReport(input: $input) {
    pdfBase64
  }
}
`

export const EXPORT_CSV_MTA_REPORT_MUTATION = `
mutation ExportMultiTouchAttributionReport($input: ExportMultiTouchAttributionReportInput!) {
  exportMultiTouchAttributionReport(input: $input) {
    csvBase64
  }
}
`

export const SAVE_MTA_REPORT_MUTATION = `
mutation SaveMultiTouchAttributionReport($input: SaveMultiTouchAttributionReportInput!) {
  saveMultiTouchAttributionReport(input: $input) {
    savedReportId
  }
}
`

export const SHARE_MTA_REPORT_MUTATION = `
mutation ShareMultiTouchAttributionReport($input: ShareMultiTouchAttributionReportInput!) {
  ShareMultiTouchAttributionReport(input: $input) {
    response
  }
}
`
