export const DAYS_HOURS_METADATA = {
  title: 'Time Range',
  caption: 'All hours between the selected start and end times will be included in the report.',
  helpText: '',
}

export const DAYS: DaysHoursValue[] = [
  { title: 'All', value: -1 },
  { title: 'Monday', value: 1 },
  { title: 'Tuesday', value: 2 },
  { title: 'Wednesday', value: 3 },
  { title: 'Thursday', value: 4 },
  { title: 'Friday', value: 5 },
  { title: 'Saturday', value: 6 },
  { title: 'Sunday', value: 7 },
]

export const HOURS: DaysHoursValue[] = [
  { title: '12 am', value: 0 },
  { title: '1 am', value: 1 },
  { title: '2 am', value: 2 },
  { title: '3 am', value: 3 },
  { title: '4 am', value: 4 },
  { title: '5 am', value: 5 },
  { title: '6 am', value: 6 },
  { title: '7 am', value: 7 },
  { title: '8 am', value: 8 },
  { title: '9 am', value: 9 },
  { title: '10 am', value: 10 },
  { title: '11 am', value: 11 },
  { title: '12 pm', value: 12 },
  { title: '1 pm', value: 13 },
  { title: '2 pm', value: 14 },
  { title: '3 pm', value: 15 },
  { title: '4 pm', value: 16 },
  { title: '5 pm', value: 17 },
  { title: '6 pm', value: 18 },
  { title: '7 pm', value: 19 },
  { title: '8 pm', value: 20 },
  { title: '9 pm', value: 21 },
  { title: '10 pm', value: 22 },
  { title: '11 pm', value: 23 },
]

export interface DaysHoursValue {
  title: string
  value: number
}

export interface DaysHours {
  days: DaysHoursValue[]
  hours: DaysHoursValue[]
}
