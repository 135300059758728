import useQueryFetch from 'hooks/useFetchQuery'
import { GetCustomAttributes } from './types'
import { GET_CUSTOM_ATTRIBUTES } from './query'

export const useCustomAttributes = user => {
  const { isLoading, isError, data } = useQueryFetch<GetCustomAttributes, undefined>({
    graphQLQuery: GET_CUSTOM_ATTRIBUTES,
    reportTitle: 'Custom-attributes',
    variables: undefined,
    skip: !user,
  })

  return { isError, isLoading, data }
}
