import React, { FC, useEffect } from 'react'
import { Snackbar, IconButton, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Close } from '@material-ui/icons'
// models
import { Alert as AlertData, AlertActionTypes } from './types'
import { useAlerts } from './AlertProvider'

interface AlertNotificationProps {
  autoHideDuration?: number | null // null disables autohide
}

export const AlertNotification: FC<AlertNotificationProps> = ({ autoHideDuration = 4000 }) => {
  const { alertState, alertDispatch } = useAlerts()
  const { alerts } = alertState

  // This useEffect will remove the last matching alert after 5 seconds
  useEffect(() => {
    if (alerts.length) {
      const indexToDelete = alerts.length - 1
      const alertValue = JSON.stringify(alerts[indexToDelete])

      setTimeout(() => {
        if (alerts[indexToDelete] && alertValue === JSON.stringify(alerts[indexToDelete])) {
          alertDispatch({
            type: AlertActionTypes.REMOVE_ALERT,
            index: alerts.length - 1,
          })
        }
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts])

  const handleClose = (i: number) => {
    alertDispatch({ type: AlertActionTypes.REMOVE_ALERT, index: i })
  }

  const renderAlerts: Function = (): JSX.Element[] => {
    return alerts.map((alert: AlertData, i: number) => {
      return (
        <Grid item key={`${alert.type}-${i}`}>
          <Alert
            action={
              <IconButton
                aria-label="close-alert"
                color="inherit"
                size="small"
                onClick={() => handleClose(i)}
                data-testid={`alert-close-${i}`}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
            severity={alert.type}
          >
            {alert.message}
          </Alert>
        </Grid>
      )
    })
  }

  if (alertState.alerts.length === 0) return null

  return (
    <Snackbar
      key={'alert-snackbar'}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={alertState.alerts.length > 0}
      autoHideDuration={autoHideDuration}
    >
      <Grid container spacing={1} style={{ flexDirection: 'column' }}>
        {renderAlerts()}
      </Grid>
    </Snackbar>
  )
}

export default AlertNotification
